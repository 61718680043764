import './services'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './services/fetchInterceptService'
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { StoresProvider } from './store/StoresProvider.js'
import { subscribe } from './services/pubsub'
import logger from './services/logger'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

subscribe('*', logger.log)

const PROD = process.env.REACT_APP_NODE_ENV === 'production'

if (PROD) {
  Sentry.init({
    dsn: 'https://9aa3eca617d34c7e8f22701c8bab0ae8@o4504192883584.ingest.sentry.io/4504197125046272',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <StoresProvider>
      <App />
    </StoresProvider>
  </Suspense>,
  document.getElementById('root')
)

serviceWorker.unregister()
