import React, { useEffect } from 'react'
import { MyKeyword } from '../_MyComponents'
import './SearchByColor.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'

const SearchByColor = observer(() => {
  const { filesStore } = useStore()

  const {
    selectedColors,
    onColorSelected,
    selectedAdvanceColorFilter,
    onAdvanceColorFilterSelected,
  } = filesStore

  const onColorClicked = (newColor) => {
    const selected = selectedColors.find(
      (color) => color.name === newColor.name
    )

    if (selected) {
      let newSelectedColors = selectedColors.filter(
        (color) => color.name != newColor.name
      )
      onColorSelected(newSelectedColors)
    } else {
      let newSelectedColors = selectedColors.concat(newColor)
      onColorSelected(newSelectedColors)
    }
  }

  const onAdvanceColorFilterClicked = (filter) => {
    const selected = selectedAdvanceColorFilter?.name === filter.name

    if (selected) {
      onAdvanceColorFilterSelected({ name: null })
    } else {
      onAdvanceColorFilterSelected(filter)
    }
  }

  return (
    <div className="filter-content-wrapper">
      <div className="filter-section">
        <div className="filter-title">
          <h2 className="h2">Styles</h2>
        </div>
        <div className="filter-tags">
          <MyKeyword
            onClick={() =>
              onAdvanceColorFilterClicked({ name: 'Warm', key: 'warm' })
            }
            selected={selectedAdvanceColorFilter?.name === 'Warm'}
            text="Warm"
          />
          <MyKeyword
            onClick={() =>
              onAdvanceColorFilterClicked({ name: 'Cold', key: 'cold' })
            }
            selected={selectedAdvanceColorFilter?.name === 'Cold'}
            text="Cold"
          />
          <MyKeyword
            onClick={() =>
              onAdvanceColorFilterClicked({ name: 'Bright', key: 'bright' })
            }
            selected={selectedAdvanceColorFilter?.name === 'Bright'}
            text="Bright"
          />
          <MyKeyword
            onClick={() =>
              onAdvanceColorFilterClicked({ name: 'Dark', key: 'dark' })
            }
            selected={selectedAdvanceColorFilter?.name === 'Dark'}
            text="Dark"
          />
          {/* <MyKeyword 
          onClick={()=> onAdvanceColorFilterClicked({name: "Black & white", key: "black_n_white"})}
          selected={selectedAdvanceColorFilter?.name === "Black & white"}
          text='Black & white'
        /> */}
          <MyKeyword
            onClick={() =>
              onAdvanceColorFilterClicked({
                name: 'Solid BG',
                key: 'solid_background',
              })
            }
            selected={selectedAdvanceColorFilter?.name === 'Solid BG'}
            text="Solid BG"
          />
          <MyKeyword
            onClick={() =>
              onAdvanceColorFilterClicked({
                name: 'Blurry BG',
                key: 'blurry_background',
              })
            }
            selected={selectedAdvanceColorFilter?.name === 'Blurry BG'}
            text="Blurry BG"
          />
        </div>
      </div>
      <div className="filter-section">
        <div className="filter-title">
          <h2 className="h2">Colors</h2>
        </div>
        <div className="filter-tags">
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: 'Red',
                avatarColor: '#EC4237',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.name === 'Red')}
            text="Red"
            avatarColor="#EC4237"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: 'Orange',
                avatarColor: '#F08040',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.name === 'Orange')}
            text="Orange"
            avatarColor="#F08040"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: 'Brown',
                avatarColor: '#B78143',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.name === 'Brown')}
            text="Brown"
            avatarColor="#B78143"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: 'Yellow',
                avatarColor: '#F5D153',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.name === 'Yellow')}
            text="Yellow"
            avatarColor="#F5D153"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: 'Green',
                avatarColor: '#30BA24',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.name === 'Green')}
            text="Green"
            avatarColor="#30BA24"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: 'Cyan',
                avatarColor: '#6CD5B8',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.name === 'Cyan')}
            text="Cyan"
            avatarColor="#6CD5B8"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: 'Blue',
                avatarColor: '#4A8BF7',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.name === 'Blue')}
            text="Blue"
            avatarColor="#4A8BF7"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: 'Purple',
                avatarColor: '#8B38F0',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.name === 'Purple')}
            text="Purple"
            avatarColor="#8B38F0"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: 'Pink',
                avatarColor: '#ED6BC8',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.name === 'Pink')}
            text="Pink"
            avatarColor="#ED6BC8"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: 'White',
                avatarColor: '#FFFFFF',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.name === 'White')}
            text="White"
            avatarColor="#FFFFFF"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: 'Grey',
                avatarColor: '#B4BAC0',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.name === 'Grey')}
            text="Grey"
            avatarColor="#B4BAC0"
            avatar={'Empty'}
          />
          <MyKeyword
            onClick={() =>
              onColorClicked({
                name: 'Black',
                avatarColor: '#000000',
                avatar: 'Empty',
              })
            }
            selected={selectedColors.find((color) => color.name === 'Black')}
            text="Black"
            avatarColor="#000000"
            avatar={'Empty'}
          />
        </div>
      </div>
    </div>
  )
})

export default SearchByColor
