import React, { useState, useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import './navbar.scss'
import './mobile.scss'
import List from '@mui/material/List'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemButton from '@mui/material/ListItemButton'
import ExpandMore from '@mui/icons-material/ExpandMore'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import { debounce } from 'lodash'
import CreateWorkspaceDialog from '../CreateWorkspaceDialog/CreateWorkspaceDialog.js'
import PlanProgress from '../PlanProgress/PlanProgress.js'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@mui/material/IconButton'
import ActionDialog from '../ActionDialog/ActionDialog.js'

// ICONS
import WhiteCollections from '../../assets/WhiteCollections.svg'
import WhiteFavorite from '../../assets/WhiteFavorite.svg'
import WhiteHome from '../../assets/WhiteHome.svg'
import WhiteTags from '../../assets/WhiteTags.svg'
import BlackCollections from '../../assets/BlackCollections.svg'
import BlackFavorite from '../../assets/BlackFavorite.svg'
import BlackHome from '../../assets/BlackHome.svg'
import BlackTags from '../../assets/BlackTags.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '225px',
    height: '100%',
    background: 'white',
    zIndex: 3,
    flexShrink: 0,
    transition: '.5s',
  },
  collapsed: {
    marginLeft: '-225px',
  },
  workspaceMenu: {
    zIndex: 10,
    cursor: 'pointer',
    padding: '0px',
  },
}))

const NavBar = observer(
  ({
    // from parent
    filterOpen,
    closeNav,
    isCollapsed,
    setView,
    view,
    setSelectedList,
    reset,
    setIsSettingsDialogOpen,
  }) => {
    const { filesStore, orgsWorkspacesStore, authenticationStore } = useStore()

    const {
      onReset,
      onIsFavoriteChanged,
      selectedTags,
      isFavorite,
      collections,
    } = filesStore

    const {
      isAllowed,
      isJustCollectionUser,
      orgPlan,
      orgPlanLimits,
      workspaceName,
      updateWorkspaceName,
      workspaceId,
      workspaces,
      changeWorkspace,
      orgType,
      role: userRole,
    } = orgsWorkspacesStore

    const { isAnonymous } = authenticationStore

    const classes = useStyles()
    const [nameValue, setNameValue] = useState(workspaceName)
    const [anchorElWorkspace, setAnchorElWorkspace] = useState(null)
    const [createWSDialog, setCreateWSDialog] = useState(false)
    const [workspaceLimitDialog, setWorkspaceLimitDialog] = useState(false)

    const isLnet = orgType === 'lnet'

    const allowedToCreate = isAllowed(RULES.CREATE_WORKSPACES) && !isLnet

    const collectionIsSelected =
      selectedTags?.filter((tag) => tag.type === 'CURATED').length > 0
    const isHome = view === 'home' && !isFavorite && !collectionIsSelected

    const handleWorkspaceClick = (event) => {
      setAnchorElWorkspace(event.currentTarget.parentNode)
    }

    const handleWorkspaceClose = () => {
      setAnchorElWorkspace(null)
    }

    const closeDialog = () => {
      setCreateWSDialog(false)
    }
    const openNewWSDialog = () => {
      setCreateWSDialog(true)
      handleWorkspaceClose()
    }

    const handleChange = (e) => {
      setNameValue(e.target.value)
      delayedHandleChange(e.target.value)
    }

    useEffect(() => {
      setNameValue(workspaceName)
    }, [workspaceName])

    //make sure Just collection user is on collections view
    useEffect(() => {
      if (
        workspaceName &&
        userRole.length > 0 &&
        (isJustCollectionUser() || !isAllowed(RULES.ALLOWED_HOMEPAGE)) &&
        view === 'home'
      ) {
        setView('collections')
      }
    }, [workspaceName, userRole])

    const delayedHandleChange = useMemo(
      () =>
        debounce((val) => {
          const newName = val
          updateWorkspaceName({ workspaceId: workspaceId, name: newName })
        }, 1500),
      [workspaceId]
    )

    const handleWorkspaceChange = (index) => {
      if (workspaces[index].workspaceId._id === workspaceId) return
      setAnchorElWorkspace(null)
      changeWorkspace(workspaces[index])
      setNameValue(workspaces[index].workspaceId.name)
      setSelectedList([])
      reset()
    }

    const onHomeClick = () => {
      setView('home')
      onReset()
    }

    const onFavoritesClick = async () => {
      setView('home')
      await onReset()
      onIsFavoriteChanged()
    }

    const onCollectionsClick = () => {
      setView('collections')
      onReset()
    }

    const onTagsClick = () => {
      setView('tags')
      onReset()
    }

    const onPersonsClick = () => {
      setView('persons')
      onReset()
    }

    const handleNewWorkspace = () => {
      if (
        orgPlanLimits.workspaceCountLimit > 0 &&
        workspaces.length >= orgPlanLimits.workspaceCountLimit
      ) {
        setWorkspaceLimitDialog(true)
      } else {
        openNewWSDialog()
      }
    }

    return (
      <>
        <div
          className={`${classes.root} left-bar left-bar-mobile ${
            filterOpen ? 'filter-open' : ''
          } ${!!isCollapsed && classes.collapsed}`}
        >
          <div className="side-menu-container">
            <div className="select-workspace">
              {
                /* organization && orgName &&  */ <TextField
                  placeholder="Add workspace name"
                  value={nameValue}
                  onChange={(e) => handleChange(e)}
                  disabled={!isAllowed(RULES.EDIT_WORKSPACES)}
                />
              }
              {(allowedToCreate || workspaces.length > 1) && (
                <IconButton size="small" onClick={handleWorkspaceClick}>
                  <ExpandMore
                    className={classes.workspaceArea}
                    style={{ cursor: 'pointer' }}
                  />
                </IconButton>
              )}
            </div>
            <Menu
              id="simple-menu"
              className={classes.workspaceMenu}
              anchorEl={anchorElWorkspace}
              keepMounted
              open={Boolean(anchorElWorkspace)}
              onClose={handleWorkspaceClose}
              disableRestoreFocus={true}
            >
              {workspaces.map(({ workspaceId }, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => handleWorkspaceChange(index)}
                    selected={workspaceId._id === workspaceId}
                  >
                    {workspaceId.name}
                  </MenuItem>
                )
              })}
              <Divider />
              {allowedToCreate && (
                <MenuItem onClick={handleNewWorkspace}>
                  <AddIcon />
                  Create a new workspace
                </MenuItem>
              )}
            </Menu>
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              sx={{
                height: 264,
                flexGrow: 1,
                maxWidth: 400,
                overflowY: 'auto',
              }}
            >
              {!isJustCollectionUser() && isAllowed(RULES.ALLOWED_HOMEPAGE) && (
                <ListItemButton
                  selected={isHome}
                  onClick={() => {
                    onHomeClick()
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '32px' }}>
                    <img src={isHome ? BlackHome : WhiteHome} alt="homepage" />
                  </ListItemIcon>
                  <p className="p">Home</p>
                </ListItemButton>
              )}
              {!(isAnonymous && collections.length == 0) && (
                <ListItemButton
                  selected={view === 'collections'}
                  onClick={() => {
                    onCollectionsClick()
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '32px' }}>
                    <img
                      src={
                        view === 'collections'
                          ? BlackCollections
                          : WhiteCollections
                      }
                      alt="collections"
                    />
                  </ListItemIcon>
                  <p className="p">Collections</p>
                </ListItemButton>
              )}
              <ListItemButton
                selected={isFavorite}
                onClick={() => {
                  onFavoritesClick()
                }}
              >
                <ListItemIcon sx={{ minWidth: '32px' }}>
                  <img
                    src={isFavorite ? BlackFavorite : WhiteFavorite}
                    alt="favorites"
                  />
                </ListItemIcon>
                <p className="p">Favorites</p>
              </ListItemButton>
              {!!isAllowed(RULES.ALLOWED_TAGMANAGMENTPAGE) && (
                <ListItemButton
                  selected={view === 'tags'}
                  onClick={() => {
                    onTagsClick()
                  }}
                >
                  <ListItemIcon sx={{ minWidth: '32px' }}>
                    <img
                      src={view === 'tags' ? BlackTags : WhiteTags}
                      alt="manage tags"
                    />
                  </ListItemIcon>
                  <p className="p">Manage Tags</p>
                </ListItemButton>
              )}
            </List>
            {userRole === 'admin' && orgPlan === 'free' && (
              <PlanProgress setIsSettingsDialogOpen={setIsSettingsDialogOpen} />
            )}
          </div>
        </div>
        {filterOpen && (
          <div className="black-bg-mobile" onClick={closeNav}></div>
        )}
        <CreateWorkspaceDialog
          isOpen={createWSDialog}
          close={closeDialog}
          isMobile={window.mobileCheck()}
        />
        <ActionDialog
          actionName={'Go to Settings'}
          action={() => setIsSettingsDialogOpen('account')}
          cancel={() => setWorkspaceLimitDialog(false)}
          question="Plan limit reached"
          comment={
            'Your current plan is limited to one workspace. Upgrade to a paid plan to create more.'
          }
          isOpen={workspaceLimitDialog}
        />
      </>
    )
  }
)

export default NavBar
