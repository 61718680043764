import React, { useState } from 'react'
import './PlanProgress.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'

import LinearProgress from '@mui/material/LinearProgress'
import { MyButton } from '../_MyComponents'
import INFO from '../../assets/info-icon.svg'
import Tooltip from '@material-ui/core/Tooltip'
import { fileSizeFormatter } from '../../util/fileSizeFormatter'

const PlanProgress = observer(
  ({
    // from parent
    setIsSettingsDialogOpen,
    hasAction = true,
  }) => {
    const { orgsWorkspacesStore } = useStore()

    const {
      planResolvedPercent,
      totalFileSize,
      totalFileCount,
      orgPlanLimits,
    } = orgsWorkspacesStore

    const fileLimit =
      orgPlanLimits.fileCountLimit !== -1
        ? orgPlanLimits.fileCountLimit
        : 'Unlimited'
    const storageLimit =
      orgPlanLimits.fileSizeLimitGB !== -1
        ? fileSizeFormatter(orgPlanLimits.fileSizeLimitGB * 1024 * 1024 * 1024)
        : 'Unlimited storage'

    return (
      <div className="plan-container">
        <div className="plan-header">
          <div className="plan-title">
            <p className="p">Plan usage</p>
            <Tooltip
              enterDelay={500}
              title={`You've used ${totalFileCount.toLocaleString(
                'en-US'
              )} of ${fileLimit.toLocaleString(
                'en-US'
              )} assets and ${fileSizeFormatter(
                totalFileSize
              )} of ${storageLimit} available`}
            >
              <img src={INFO} alt="info" />
            </Tooltip>
          </div>
          <p className="p">{(planResolvedPercent * 100).toFixed(0)}%</p>
        </div>
        <LinearProgress
          variant="determinate"
          style={{ color: '#FBC02D' }}
          color="inherit"
          value={Math.min(planResolvedPercent * 100, 100)}
        />
        {hasAction && (
          <MyButton
            variant="contained"
            color={'primary'}
            className={planResolvedPercent * 100 < 70 && 'gray'}
            onClick={() => setIsSettingsDialogOpen('account')}
            size={'small'}
          >
            Manage Plan
          </MyButton>
        )}
      </div>
    )
  }
)

export default PlanProgress
