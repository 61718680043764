import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js' // Import useStore hook

import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

const CreateWorkspaceDialog = observer(
  ({
    // from parent
    isOpen,
    close,
    isMobile,
  }) => {
    const { orgsWorkspacesStore } = useStore()
    const { addWorkspace } = orgsWorkspacesStore

    const [workspaceName, setworkspaceName] = useState('')

    const handleChange = (e) => {
      setworkspaceName(e.target.value)
    }

    const onClickEnter = async (e) => {
      if (
        e.key === 'Enter' &&
        e.target.value &&
        e.target.value.length > 1 &&
        !e.repeat
      ) {
        createWorkspace()
      }
    }

    const createWorkspace = () => {
      addWorkspace({ workspaceName })
      close()
    }

    return (
      <Dialog
        onClose={close}
        fullWidth={isMobile ? false : true}
        maxWidth={'xs'}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
      >
        <DialogTitle id="form-dialog-title">New workspace</DialogTitle>
        <DialogContent>
          <DialogContentText>Workspace name</DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            onChange={handleChange}
            size="small"
            placeholder="My new workspace"
            onKeyDown={onClickEnter} //TODO DO GUY!
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} variant="contained" color="grey">
            Cancel
          </Button>
          <Button
            onClick={createWorkspace}
            color="primary"
            variant="contained"
            disabled={!workspaceName}
          >
            Create workspace
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

export default CreateWorkspaceDialog
