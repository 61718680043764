import React, { useEffect, useState, useMemo } from 'react'
import './style.scss'

import EDIT from '../../assets/edit-icon.svg'
import { fileSizeFormatter } from '../../util/fileSizeFormatter'
import TagList from '../TagList/TagList.js'
import { MyKeyword } from '../_MyComponents'

import { host } from '../../services/host'
import { getInitToken } from '../../services/apis/util'

import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import InsertDriveFileSharpIcon from '@material-ui/icons/InsertDriveFileSharp'
import COLORS from '../Colors/colors.js'
import './style.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'

import { get } from 'mobx'

const TableView = observer(
  ({
    // from parent
    previewDialog,
  }) => {
    const { filesStore } = useStore()

    const {
      categories,
      setSelectedList,
      selectedList,
      files,
      filesCount,
      addFileTag: onAddTag,
      removeFileTag: onRemoveTag,
      allTags,
      addToFileViewed,
      updateFileName,
      FILES_IN_PAGE,
      getFilesFiltered,
      updateFileDescription,
      currentFilesPage,
      setTablePage,
    } = filesStore

    //////////////////////////////////////////////
    // Table view
    //////////////////////////////////////////////

    const [rowSelected, setRowSelected] = useState([])
    const [anchor, setAnchor] = useState(null)
    const [anchorCell, setAnchorCell] = useState(null)

    // sync rowSelected with selectedList
    useEffect(() => {
      const newSelected = []
      files.forEach((file) => {
        if (selectedList.includes(file)) {
          newSelected.push(file._id)
        }
      })
      setRowSelected(newSelected)
    }, [selectedList])

    // calculate category columns
    const categoryColumns = useMemo(() => {
      console.log(anchor, 'anchor')
      console.log(anchorCell, 'anchorRow')
      const catColumns =
        categories?.categoriesArrays?.map((category) => {
          return {
            sortable: false,
            field: category.nameLowercase,
            headerName: category.name,
            width: 150,
            renderCell: (params) => (
              <>
                <div className="tag-cell">
                  <div className="flex-row">
                    {params.value.map((tag) => (
                      <MyKeyword
                        text={tag.name}
                        //onClick={() => onTagClick(keyword)}
                        key={`key_${tag._id}`}
                        disabled
                      />
                    ))}
                  </div>
                  <div
                    className="add-tag-button"
                    onClick={(e) => {
                      setAnchor(e.currentTarget.parentElement.parentElement)
                      setAnchorCell({ row: params.id, col: category._id })
                    }}
                  >
                    <img src={EDIT} alt="edit" className="hover-button" />
                  </div>
                </div>
                {!!anchor &&
                  anchorCell.row === params.id &&
                  anchorCell.col === category._id && (
                    <TagList
                      width={{ width: '250px' }}
                      anchor={anchor}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClose={() => setAnchor(null)}
                      tags={params.api.getRow(params.id).allTags}
                      allTags={allTags}
                      onAddTag={onAddTag}
                      onRemoveTag={onRemoveTag}
                      fileId={params.id}
                      key={params.id}
                    />
                  )}
              </>
            ),
          }
        }) || []

      const miscColumn = {
        sortable: false,
        field: 'Miscellaneous',
        headerName: !!categories?.categoriesArrays?.length
          ? 'Miscellaneous'
          : 'Tags',
        width: 250,
        renderCell: (params) => (
          <div className="tag-cell">
            <div className="flex-row">
              {params.value.map((tag) => (
                <MyKeyword
                  text={tag.name}
                  //onClick={() => onTagClick(keyword)}
                  key={`key_${tag._id}`}
                  disabled
                />
              ))}
            </div>
            <div
              className="add-tag-button"
              onClick={(e) => {
                setAnchor(e.currentTarget.parentElement.parentElement)
                setAnchorCell({ row: params.id, col: 'misc' })
              }}
            >
              <img src={EDIT} alt="edit" className="hover-button" />
            </div>
            {!!anchor &&
              anchorCell.row === params.id &&
              anchorCell.col === 'misc' && (
                <TagList
                  width={{ width: '250px' }}
                  anchor={anchor}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  onClose={() => setAnchor(null)}
                  tags={params.api.getRow(params.id).allTags}
                  allTags={allTags}
                  onAddTag={onAddTag}
                  onRemoveTag={onRemoveTag}
                  fileId={params.id}
                  key={params.id}
                />
              )}
          </div>
        ),
      }

      return [...catColumns, miscColumn]
    }, [categories, anchor])

    // calculate columns
    const columns = useMemo(() => {
      const mainColumns = [
        {
          field: 'thumbnail',
          headerName: 'Thumbnail',
          sortable: false,
          headerAlign: 'left',
          align: 'center',
          width: 100,
          renderCell: (params) =>
            params.row.thumbnail_xxx ? (
              <div
                className="thumbnail-cell"
                onClick={() => {
                  filePreview(params.api.getRow(params.id).fileId)
                }}
              >
                <img
                  src={params.row.thumbnail_xxx}
                  alt="thumbnail"
                  className="thumbnail-img"
                />
              </div>
            ) : (
              <div
                className="file-type-icon-wrappaer"
                onClick={() => {
                  filePreview(params.api.getRow(params.id).fileId)
                }}
              >
                <InsertDriveFileSharpIcon
                  fontSize="small"
                  size="small"
                  className="file-type-icon"
                  style={{ color: COLORS[params.api.getRow(params.id).ext] }}
                />
              </div>
            ),
        },
        // using _x x x to prevent accidental filed duplication with category names
        {
          field: 'name_xxx',
          headerName: 'Name',
          width: 200,
          editable: true,
          sortable: false,
        },
        {
          field: 'description_xxx',
          headerName: 'Description',
          width: 200,
          editable: true,
          sortable: false,
        },
        { field: 'type_xxx', headerName: 'Type', width: 100, sortable: false },
        { field: 'size_xxx', headerName: 'Size', width: 100, sortable: false },
        { field: 'date_xxx', headerName: 'Date', width: 150, sortable: false },
        {
          field: 'owner_xxx',
          headerName: 'Owner',
          width: 150,
          sortable: false,
        },
      ]

      const checkbox = {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        hideable: false,
      }

      return [checkbox, ...mainColumns, ...categoryColumns]
    }, [categories, anchor])

    // calculate row items
    const rows = useMemo(() => {
      let categoriesMap = {}
      for (let category of categories?.categoriesArrays || []) {
        categoriesMap[category._id.toString()] = category
      }

      const tempRows = files?.map((file, idx) => {
        let categoriesLocal = { Miscellaneous: [] }
        for (let categoryId of Object.keys(categoriesMap)) {
          categoriesLocal[categoriesMap[categoryId.toString()].nameLowercase] =
            []
        }

        for (const tag of file.tags || []) {
          if (tag.categories.length === 0) {
            categoriesLocal['Miscellaneous'].push(tag)
          } else {
            for (const category of tag.categories || []) {
              let c = categoriesMap[category]
              if (!c) {
                categoriesLocal['Miscellaneous'].push(tag)
              } else {
                categoriesLocal[c.nameLowercase].push(tag)
              }
            }
          }
        }

        let row = {
          id: file._id,
          name_xxx: file.name,
          size_xxx: fileSizeFormatter(file.size),
          type_xxx: file.ext,
          date_xxx: new Date(file.createdAt).toLocaleDateString('en-us', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
          owner_xxx: file.username,
          description_xxx: file.description,
          thumbnail_xxx:
            file.thumbnail ||
            `${host}/files/${file.fileId}/thumbnail?token=${getInitToken()}`,
          // not columns but used for data
          allTags: file.tags,
          ext: file.ext,
          fileId: file.fileId,
          'data-id': file._id, // for selecto
        }

        return { ...row, ...categoriesLocal }
      })
      return tempRows
    }, [categories, files, rowSelected, anchor])

    const filePreview = (fileId) => {
      previewDialog(true)
      addToFileViewed({ fileId })
    }

    const nextPage = async () => {
      await getFilesFiltered({ skip: files.length })
    }

    const handleOnPaginationModelChange = async (newPaginationModel) => {
      if (newPaginationModel.page !== currentFilesPage) {
        var pages = Math.floor(files.length / FILES_IN_PAGE) - 1

        if (newPaginationModel.page > pages) {
          await nextPage()
        }
      }

      setTablePage(newPaginationModel.page)
    }

    console.log(currentFilesPage)

    return (
      <DataGrid
        disableRowSelectionOnClick
        disableColumnFilter
        getRowHeight={() => 'auto'}
        getRowId={(row) => row?.id}
        rows={rows}
        columns={columns}
        rowCount={filesCount}
        //paginationMode="server"
        paginationModel={{
          page: currentFilesPage,
          pageSize: FILES_IN_PAGE,
        }}
        onPaginationModelChange={handleOnPaginationModelChange}
        initialState={{
          pagination: {
            paginationModel: {
              page: currentFilesPage,
              pageSize: FILES_IN_PAGE,
            },
          },
          columns: {
            columnVisibilityModel: {
              owner_xxx: false,
              description_xxx: false,
            },
          },
        }}
        pageSizeOptions={[FILES_IN_PAGE]}
        checkboxSelection
        rowSelectionModel={rowSelected}
        onRowSelectionModelChange={(newSelection) => {
          setRowSelected(newSelection)
          const newFiles = []
          if (newSelection.length) {
            var newSelectionFiltered = newSelection
            if (newSelection.length === files.length) {
              //all files are selected in page
              newSelectionFiltered = newSelection.slice(
                currentFilesPage * FILES_IN_PAGE,
                (currentFilesPage + 1) * FILES_IN_PAGE
              )
            }
            //regular case
            newSelectionFiltered.forEach((file) => {
              const tempFile = files.find((f) => f._id === file)
              newFiles.push(tempFile)
            })
            setSelectedList(newFiles)
          } else {
            setSelectedList([])
          }
        }}
        processRowUpdate={(updatedRow, originalRow) => {
          if (updatedRow.name_xxx !== originalRow.name_xxx) {
            updateFileName({
              fileId: updatedRow.id,
              name: updatedRow.name_xxx,
            })
          }
          if (updatedRow.description_xxx !== originalRow.description_xxx) {
            updateFileDescription({
              fileId: updatedRow.id,
              description: updatedRow.description_xxx,
            })
          }
          return updatedRow
        }}
        onProcessRowUpdateError={(params) => {
          console.log(params)
        }}
        sx={{
          '& .MuiDataGrid-cell': {
            padding: '8px',
          },
        }}
      />
    )
  }
)

export default TableView
