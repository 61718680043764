import React, { useState, useRef, useEffect } from 'react'
import './Persons.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'

import Person from '../Person/Person.js'
import InfiniteScroll from 'react-infinite-scroll-component'

const Persons = observer(
  ({
    // from parent
    setView,
  }) => {
    const { filesStore } = useStore()

    const { persons, getAllPersons, PERSONS_IN_PAGE } = filesStore

    const nextPage = () => {
      console.log('nextPage')
      getAllPersons({ skip: persons.length })
    }

    const personItems = persons
      //.sort((a, b) => a.name?.localeCompare(b.name))
      .slice()
      .sort((a, b) => b.count - a.count)
      .sort((a, b) => a._id.toString().localeCompare(a._id.toString()))
      .map((person, idx) => (
        <Person
          key={`person-${person._id}`}
          person={person}
          itemId={`${person._id}-k`}
          setView={setView}
        ></Person>
      ))

    return (
      <div className="persons-container">
        {/* no persons */}
        {persons?.length === 0 && (
          <div className="persons-empty-state">
            <div className="h1">No People here yet</div>
            <div className="h2 light">Upload more assets with people in it</div>
          </div>
        )}
        {/* persons */}
        {!!persons?.length > 0 && (
          <div className="persons-content-container" id="scollablePersons">
            <div className="persons-header">
              <h1 className="h1">People in images</h1>
            </div>
            <div className="persons-content">
              <InfiniteScroll
                className="persons-grid"
                dataLength={persons?.length}
                scrollThreshold={0.5}
                next={nextPage}
                hasMore={
                  !!persons?.length && persons?.length % PERSONS_IN_PAGE === 0
                }
                loader={<p className="infinite-scroll-message">Loading...</p>}
                /*endMessage={
								persons?.length > PERSONS_IN_PAGE && (
									<p className="infinite-scroll-message">
										Yay! You have seen it all
									</p>
								)
							}*/
                scrollableTarget="scollablePersons"
                style={{ overflow: 'hidden' }}
              >
                {personItems}
              </InfiniteScroll>
            </div>
          </div>
        )}
      </div>
    )
  }
)

export default Persons
