import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'

import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import history from '../../services/history'
import './style.scss'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from '../_MyComponents'
import logo from '../../assets/logo.svg'
import SIGNUP from '../../assets/signup.jpg'
import { MyButton } from '../_MyComponents'
import GOOGLE from '../../assets/google-icon.svg'

const communityclipsLogo =
  'https://s3.eu-west-1.amazonaws.com/tagbox.public.bucket/whitelabel/Community_Clips_Logo2.jpg'

const communityclipsCoverImage =
  'https://s3.eu-west-1.amazonaws.com/tagbox.public.bucket/whitelabel/community_clips_1x1_login_page_image.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    width: '300px',
    display: 'block',
    padding: '15px',
    margin: '50px auto',
    background: '#f3f3f3',
    border: '1px solid #d6d6d6',
    borderRadius: '3px',
  },
  button: {
    display: 'block',
    width: '100%',
    margin: '20px 0',
  },
  input: {
    width: '100%',
    marginBottom: '20px',
    background: 'white',
  },
  title: {
    paddingBottom: '15px',
    fontWeight: 'bold',
    fontSize: '22px',
  },
  register: {
    textAlign: 'center',
    padding: '10px 0',
    marginTop: '20px',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  logo: {
    textAlign: 'center',
    fontSize: '32px',
    color: 'grey',
    marginTop: '20px',
  },
  error: {
    color: 'red',
  },
}))

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  //console.log("location" ,useLocation())
  return new URLSearchParams(useLocation().search)
}

const Register = observer(() => {
  const { registerStore, authenticationStore } = useStore()

  const {
    credentialChange,
    password,
    message,
    username,
    usernameError,
    showPassword,
    toggleShowPassword,
    confirmPassword,
    passwordError,
    onSignUp,
    passwordErrorMessage,
    confirmPasswordErrorMessage,
    usernameErrorMessage,
    emailFromInvitation,
    displayNameErrorMessage,
    displayName,
  } = registerStore

  const { googleLogin } = authenticationStore

  const classes = useStyles()

  const [isReady, setIsReady] = useState(false)
  const [isCommunityClips, setIsCommunityClips] = useState(false)

  let query = useQuery()

  useEffect(() => {
    let saml = query.get('saml')

    var location_origin = window.location.origin

    const checkLocation = () => {
      if (location_origin.includes('communityclips.com')) {
        setIsCommunityClips(true)
      } else if (saml) {
        if (saml === 'communityclips') {
          setIsCommunityClips(true)
        } else {
          setIsReady(true)
        }
      } else {
        setIsReady(true)
      }
    }

    checkLocation()
  }, [])

  const handleEnter = (e) => {
    if (e.keyCode == 13) {
      onSignUp()
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div className="signup-wrapper">
        {!!isReady && (
          <img src={SIGNUP} className="signup-image" alt="signup" />
        )}
        {!!isCommunityClips && (
          <img
            src={communityclipsCoverImage}
            className="signup-image"
            alt="signup"
          />
        )}

        <div className="login-form">
          {!!isReady && <img src={logo} className="logo" alt="logo" />}
          {!!isCommunityClips && (
            <img src={communityclipsLogo} className="logo" alt="logo" />
          )}
          <div className="form-container">
            <h1 className="h1">Signup</h1>
            <FormControl
              className={clsx(classes.margin, classes.input)}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-username">
                Email
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-username"
                type="email"
                required
                value={username}
                onChange={(e) => {
                  credentialChange({
                    username: e.target.value,
                    password,
                    confirmPassword,
                    displayName,
                  })
                }}
                onKeyDown={(e) => handleEnter(e)}
                labelWidth={40}
                disabled={emailFromInvitation}
              />
            </FormControl>
            <FormControl
              className={clsx(classes.margin, classes.input)}
              variant="outlined"
            >
              <InputLabel htmlFor="fullName">Full Name</InputLabel>
              <OutlinedInput
                id="fullName"
                type="text"
                required
                value={displayName}
                onChange={(e) => {
                  credentialChange({
                    displayName: e.target.value,
                    password,
                    confirmPassword,
                    username,
                  })
                }}
                onKeyDown={(e) => handleEnter(e)}
                labelWidth={75}
              />
            </FormControl>
            <div className={classes.error}>{usernameError}</div>
            <FormControl
              className={clsx(classes.margin, classes.input)}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                required
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => {
                  credentialChange({
                    password: e.target.value,
                    username,
                    confirmPassword,
                    displayName,
                  })
                }}
                onKeyDown={(e) => handleEnter(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={toggleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            <FormControl
              className={clsx(classes.margin, classes.input)}
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-confirm-password">
                Confirm password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                type={showPassword ? 'text' : 'password'}
                required
                value={confirmPassword}
                onChange={(e) => {
                  credentialChange({
                    confirmPassword: e.target.value,
                    password,
                    username,
                    displayName,
                  })
                }}
                onKeyDown={(e) => handleEnter(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={toggleShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={135}
              />
            </FormControl>
            <div className="login-error">
              {' '}
              {(message ||
                passwordErrorMessage ||
                confirmPasswordErrorMessage ||
                usernameErrorMessage ||
                displayNameErrorMessage) && (
                <Typography color="error">
                  {message ||
                    passwordErrorMessage ||
                    confirmPasswordErrorMessage ||
                    usernameErrorMessage ||
                    displayNameErrorMessage}
                </Typography>
              )}
            </div>
            <div className={classes.error}>{passwordError}</div>
            <Button
              variant="contained"
              color="primary"
              onClick={onSignUp}
              className={classes.button}
            >
              Sign up
            </Button>

            {!!isReady && (
              <div className="other-login-title">
                <span>or</span>
              </div>
            )}

            {!!isReady && (
              <MyButton
                variant="contained"
                color="secondary"
                className="button"
                startIcon={
                  <img src={GOOGLE} style={{ width: '20px' }} alt="google" />
                }
                onClick={googleLogin}
              >
                Sign up with Google
              </MyButton>
            )}

            <div
              className={classes.register}
              onClick={() => {
                history.push('/login' + history.location.search)
              }}
            >
              Already have a user? Login
            </div>
          </div>
          <div className="tos-disclaimer">
            By clicking on 'Sign up' above, you are agreeing to the{' '}
            <a
              href="https://www.tagbox.io/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              href="https://www.tagbox.io/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </MuiThemeProvider>
  )
})

export default Register
