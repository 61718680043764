import React, { useState, useRef } from 'react'
import './Collection.scss'
import './mobile.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js' // Import useStore hook

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import '@szhsin/react-menu/dist/index.css'
import Tooltip from '@material-ui/core/Tooltip'
import { debounce } from 'lodash'
import COLLECTION from '../../assets/collection-icon.svg'
import moment from 'moment'
import { getInitToken } from '../../services/apis/util'
import { host } from '../../services/host'

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: '16px',
    fontWeight: '700',
  },
}))

const Collection = observer(
  ({
    // from parent
    fullCollection,
  }) => {
    const { filesStore } = useStore()
    const { getCurrentCollection, editCollection } = filesStore

    const { name, description, updatedAt, count, thumbnails, _id } =
      fullCollection

    const classes = useStyles()
    const itemRef = useRef()

    const collectionPreview = () => {
      //previewDialog(true)
      //addToFileViewed({ fileId });
      console.log('Open collection ' + _id)
      getCurrentCollection(_id)
    }

    const delayedHandleChange = debounce((val) => {
      const newName = val
      editCollection({ id: _id, name: newName, description })
    }, 1500)

    const handleChange = (e) => {
      delayedHandleChange(e.target.value)
    }

    const parseDate = (date) => {
      const res = moment(moment(date).subtract(1, 'seconds')).fromNow()
      return res
    }

    const getFileThumbnail = ({ thumbnail, fileId, file, ext }) => {
      const src =
        file?.Thumbnail || thumbnail
          ? file?.Thumbnail || thumbnail
          : `${host}/files/${fileId}/thumbnail?token=${getInitToken()}`

      return (
        <div key={fileId} className="collection-img">
          <img className="thumbnail" src={src} />
        </div>
      )
    }

    const filesThumbnails = () => {
      return thumbnails.map((fullThumbnail, idx) => {
        if (!fullThumbnail) {
          return (
            <div key={idx} className="collection-img">
              <img src={COLLECTION} />
            </div>
          )
        } else {
          const { thumbnail, fileId, ext, file, setImageError } = fullThumbnail
          return getFileThumbnail({
            thumbnail,
            fileId,
            ext,
            file,
            setImageError,
          })
        }
      })
    }

    return (
      <div className="collection-container" key={_id}>
        <Paper
          className={'collection' + ' mobile-collection on-hover '}
          elevation={3}
          ref={itemRef}
          onClick={() => collectionPreview()}
        >
          <div className="img-container">{filesThumbnails()}</div>
        </Paper>
        <Tooltip title={name}>
          <TextField
            defaultValue={name}
            fullWidth
            //disabled={true}
            className="h2"
            InputProps={{ className: classes.input }}
            onChange={(e) => handleChange(e)}
          />
        </Tooltip>
        <div>
          <p className="p light">
            {count + ` files | updated ` + parseDate(updatedAt)}
          </p>
        </div>
      </div>
    )
  }
)

export default Collection
