import React, { useState, useMemo, useEffect } from 'react'
import './style.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'

import { useLocation, useHistory } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import TopBar from '../TopBar/TopBar.js'
import TagsFilter from '../TagsFilter/TagsFilter.js'
import NavBar from '../NavBar/NavBar.js'
import Files from '../Files/Files.js'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from '../_MyComponents'
import TagsManagment from '../TagsManagment/TagsManagment.js'
import Persons from '../Persons/Persons.js'
import Settings from '../Settings/Settings.js'
import UploadWizard from '../UploadWizard/UploadWizard.js'
import ImportWizard from '../ImportWizard/ImportWizard.js'
import * as FullStory from '@fullstory/browser'
import CircularProgress from '@material-ui/core/CircularProgress'
import BulkActionBar from '../BulkActionBar/BulkActionBar.js'
import ActionDialog from '../ActionDialog/ActionDialog.js'
import BulkEdit from '../BulkEdit/BulkEdit.js'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { RULES } from '../../store/OrgsWorkspacesStore/permissions'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@mui/material/Slide'
import Alert from '@mui/material/Alert'
import Preview from '../Preview/Preview.js'
import Collections from '../Collections/Collections.js'
import CreateCollectionDialog from '../CreateCollectionDialog/CreateCollectionDialog.js'
import LAUNCH from '../../assets/launch.svg'
import { MyButton } from '../_MyComponents'
import * as CryptoJS from 'crypto-js'
import LinearProgress from '@mui/material/LinearProgress'
import Intercom from '@intercom/messenger-js-sdk'

const PROD = process.env.REACT_APP_NODE_ENV === 'production'
const DEMO_ORGID = '618fad7e791554000d429047'

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  //console.log("location" ,useLocation())
  return new URLSearchParams(useLocation().search)
}

const Home = observer(
  (
    {
      // from parent
    }
  ) => {
    const { filesStore, orgsWorkspacesStore, authenticationStore } = useStore()

    const {
      onReset,
      uploadToS3,
      deleteBulkFiles,
      foundFilesSnackbar: snackbar,
      tagIsSuggest,
      tagSuggestion,
      tagSuggestIsExist,
      tagSuggestionCount,
      lastTag,
      renameTag,
      cancelTagSuggestions,
      addToFileViewed,
      currentCollection,
      deleteCollection,
      getCurrentCollection,
      files,
      isSearchMode,
      selectedList,
      setSelectedList,
      isBulkDownloading,
      mergePersons,
      duplicatePersons,
      selectAllFilteredFiles,
      syncJob,
      closeSyncJob,
      driveFilesCount,
      setFileViewMode,
      driveFoldersNames,
      mainView: view,
      setMainView: setView,
      settingsDialogView: isSettingsDialogOpen,
      setSettingsDialogView: setIsSettingsDialogOpen,
    } = filesStore

    const {
      organization,
      getUsersFromWorkspace,
      orgName,
      isAllowed,
      orgId,
      workspace,
      workspaceName,
      workspaceId,
      workspaces,
      planResolvedPercent,
      orgPlan,
      orgInfo,
      orgPlanLimits,
      role,
    } = orgsWorkspacesStore

    const { username, userState, getUserState, isAnonymous } =
      authenticationStore
    const [isPlanPrompt, setIsPlanPrompt] = useState(false)
    const [isCleanupDialogOpen, setIsCleanupDialogOpen] = useState(false)
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)
    const [isImportWizardOpen, setIsImportWizardOpen] = useState(false)
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)
    const [filterOpen, setFilterOpen] = useState(false)
    const [viewReady, setViewReady] = useState(false)
    const [isBookmarkOpen, setIsBookmarkOpen] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [showBulkEditDialog, setShowBulkEditDialog] = useState(false)
    const [type, setType] = useState('REGULAR')
    const [deleteCollectionId, setDeleteCollectionId] = useState(null)
    const [showDeleteCollectionDialog, setShowDeleteCollectionDialog] =
      useState(false)
    const [tagSnackbar, setTagSnackbar] = useState(false)
    const [showEditCollectionDialog, setShowEditCollectionDialog] =
      useState(false)
    const [accountBlock, setAccountBlock] = useState(false)

    /////////////////////////////////////////////////////
    // MOBILE CHECK
    /////////////////////////////////////////////////////

    window.mobileCheck = () => {
      let check = false
      ;(function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
            a.substr(0, 4)
          )
        )
          check = true
      })(navigator.userAgent || navigator.vendor || window.opera)
      return check
    }

    /////////////////////////////////////////////////////
    // CHANGE BROWSER TAB TITLE
    /////////////////////////////////////////////////////

    useEffect(() => {
      // updates browser tab title
      document.title = workspaceName

      // updates open graph title
      const ogTitleMetaTag = document.querySelector('meta[property="og:title"]')
      // if the meta tag exists, update its content
      if (ogTitleMetaTag) {
        ogTitleMetaTag.setAttribute('content', workspaceName)
      } else {
        // if the meta tag does not exist, create it
        const metaTag = document.createElement('meta')
        metaTag.setAttribute('property', 'og:title')
        metaTag.setAttribute('content', workspaceName)
        document.head.appendChild(metaTag)
      }
    }, [workspaceName])

    /////////////////////////////////////////////////////
    // ANALYTICS
    /////////////////////////////////////////////////////

    const isOwner = useMemo(() => {
      if (organization && organization.creatorUsername === username) return true
      return false
    }, [organization, username])

    const orgAge = useMemo(() => {
      if (organization && organization.createdAt) {
        const createdAt = new Date(organization.createdAt)
        const today = new Date()
        const diff = today - createdAt
        const days = diff / (1000 * 60 * 60 * 24)

        return days
      }
      return -1
    }, [organization])

    // useMemo for isNew, isNewish, isActivated
    const isNewOrg = useMemo(() => {
      if (orgAge < 1 && orgAge > 0) return true
      return false
    }, [orgAge])
    const isNewishOrg = useMemo(() => {
      if (orgAge >= 1) return true
      return false
    }, [orgAge])
    const isActivatedOrg = useMemo(() => {
      if (orgAge >= 21) return true
      return false
    }, [orgAge])

    //get utm params
    let query = useQuery()

    let utm = useMemo(() => {
      const utmSource = query.get('utm_source')
      const utmMedium = query.get('utm_medium')
      const utmCampaign = query.get('utm_campaign')
      if (utmSource || utmMedium || utmCampaign) {
        return {
          utmSource,
          utmMedium,
          utmCampaign,
        }
      }
      return null
    }, [query])

    const userData = {
      workspaceName: workspaceName,
      workspaceId: workspaceId,
      orgPlan: orgPlan,
      orgId: orgId,
      orgName: orgName,
      email: username,
      isOwner: isOwner,
      userRole: role,
      planUsagePercent: Number((planResolvedPercent * 100).toFixed(0)),
      orgEntity: orgInfo?.orgEntity,
      orgSize: orgInfo?.orgSize,
      orgIndustry: orgInfo?.orgAssetsType,
    }

    if (utm && isNewOrg) {
      userData.utmSource = utm.utmSource
      userData.utmMedium = utm.utmMedium
      userData.utmCampaign = utm.utmCampaign
    }

    /////////////////////////////////////////////////////
    // INTERCOM
    /////////////////////////////////////////////////////

    useEffect(() => {
      Intercom({
        app_id: 'ephn9qzc',
        user_id: userData.email,
        email: userData.email,
        name: userState?.userId?.name,
        user_role: userData.userRole,
        account_name: userData.orgName,
        created_at: userData.createdAt,
        account_owner: userData.isOwner,
        org_id: userData.orgId,
        org_name: userData.orgName,
        org_plan: userData.orgPlan,
        plan_usage: userData.planUsagePercent,
        org_entity: userData.orgEntity,
        org_size: userData.orgSize,
        org_industry: userData.orgIndustry,
      })
    })

    /////////////////////////////////////////////////////
    // FILE PREVIEW STATE IN URL
    /////////////////////////////////////////////////////

    useEffect(() => {
      let fileId = query.get('openedFile')
      let collections = query.get('collections')
      let favorite = query.get('favorite')
      let tags = query.get('tags')
      let collectionId = query.get('collectionId')
      let view = query.get('view')

      if (workspaceId) {
        if (fileId) {
          //wait to token to load
          filePreview(fileId)
        }

        if (collections) {
          //?collections=open
          setView('collections')
          if (collectionId) {
            getCurrentCollection(collectionId)
          }
        }
        if (favorite) {
          //?favorite=true
          setView('home')
        }
        if (tags) {
          //?tags=open
          setView('tags')
        }
        if (view) {
          if (['table', 'grid'].includes(view)) {
            setFileViewMode(view)
          } else {
            //remove invalid view
            query.delete('view')
            historyDOM.replace({
              search: query.toString(),
            })
          }
        }
      }
    }, [workspaceId])

    /////////////////////////////////////////////////////
    // LOCAL STORAGE
    /////////////////////////////////////////////////////

    const useLocalStorage = (keyName, defaultValue) => {
      const [storedValue, setStoredValue] = useState(() => {
        try {
          const value = window.localStorage.getItem(keyName)

          if (value) {
            return JSON.parse(value)
          } else {
            window.localStorage.setItem(keyName, JSON.stringify(defaultValue))
            return defaultValue
          }
        } catch (err) {
          return defaultValue
        }
      })

      const setValue = (newValue) => {
        try {
          window.localStorage.setItem(keyName, JSON.stringify(newValue))
        } catch (err) {}
        setStoredValue(newValue)
      }

      return [storedValue, setValue]
    }

    const [collapseSidebar, setCollapseSidebar] = useLocalStorage(
      'collapse',
      false
    )
    // walkthrough state
    const [walkthrough, setWalkthrough] = useLocalStorage('walkthrough', {
      upload: true,
      filters: true,
      tagMgmt: true,
      import: true,
    })
    // specific to the onboarding in upload, should be refactored into walkthrough
    const [onboardingMode, setOnboardingMode] = useLocalStorage(
      'onboardingMode',
      true
    )

    useEffect(() => {
      if (onboardingMode && files.length) {
        setOnboardingMode(false)
      }
    }, [files])

    /////////////////////////////////////////////////////
    // ACCOUNT DISABLED
    /////////////////////////////////////////////////////

    useEffect(() => {
      if (organization && organization.enabled === false) {
        setAccountBlock(true)
      } else {
        return
      }
    }, [organization])

    /////////////////////////////////////////////////////
    // MULTI SELECT
    /////////////////////////////////////////////////////

    const handleSelectAll = (e) => {
      if (
        (e.ctrlKey || e.metaKey) &&
        (e.keyCode === 65 || e.keyCode === 97) &&
        (e.target.nodeName !== 'INPUT' || e.target.type === 'checkbox') && // exclude input fields, except  checkbox inputs
        e.target.nodeName !== 'TEXTAREA' &&
        e.target.classList[0] !== 'comPlainTextContentEditable' // tag mgmt editable fields
      ) {
        e.preventDefault()
        // prevent default select all behavior when in upload is open
        if (!isUploadDialogOpen) {
          selectAllFilteredFiles()
        }
      }
    }

    useEffect(() => {
      document.addEventListener('keydown', handleSelectAll)
      return () => {
        document.removeEventListener('keydown', handleSelectAll)
      }
    }, [files, isUploadDialogOpen])

    const closeBulkBar = () => {
      setSelectedList([])
    }

    const getList = () => {
      const newList = selectedList.map(({ name }) => name)
      return newList
    }

    const bulkRemove = async () => {
      const idsToRemove = selectedList.map((file) => file._id)
      const res = await deleteBulkFiles(idsToRemove)
      setShowDialog(false)
      setSelectedList([])
    }

    /////////////////////////////////////////////////////
    // CATEGORIES
    /////////////////////////////////////////////////////

    const location = useLocation()
    const historyDOM = useHistory()

    const onDeleteCollection = async (id) => {
      const queryParams = new URLSearchParams(location.search)
      console.log({ queryParams })

      await deleteCollection(id)
      if (queryParams.has('collectionId')) {
        console.log('deleting collectionId')
        queryParams.delete('collectionId')
        historyDOM.replace({
          search: queryParams.toString(),
        })
      } else {
        console.log('else?')
      }

      setDeleteCollectionId(null)
      setShowDeleteCollectionDialog(false)
      onReset()
    }

    /////////////////////////////////////////////////////
    // UPLOADER
    /////////////////////////////////////////////////////

    const onDrop = (acceptedFiles) => {
      if (isAllowed(RULES.UPLOAD_DOCUMENTS)) {
        if (planResolvedPercent * 100 < 100 || orgPlan !== 'free') {
          setIsUploadDialogOpen(true)
          uploadToS3(acceptedFiles)
        } else {
          setIsPlanPrompt(true)
        }
      }
    }

    const { getRootProps, getInputProps, isDragAccept } = useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
      //noDrag: true,
      noDragEventsBubbling: true,
      autoFocus: true,
    })

    const { ...restRootProps } = getRootProps()

    /////////////////////////////////////////////////////
    // VIEW READY
    /////////////////////////////////////////////////////

    useMemo(() => {
      if (organization && Array.isArray(files)) setViewReady(true)
    }, [organization, files])

    useMemo(() => {
      if (workspaceId) {
        getUsersFromWorkspace()
        getUserState()
        //getFilesUsers()
      }
    }, [workspaceId, getUsersFromWorkspace])

    useEffect(() => {
      const identifyFS = () => {
        if (PROD && orgId) {
          //&& username && username.length > 0){

          //exclude Israaid and Tagbox sessions
          if (
            username &&
            username.length > 0 &&
            username.includes('tagbox.io')
          ) {
            console.log('fullstory abort')
            return
          }

          FullStory.identify(username)
          // optimizes google algo to target better users

          // don't send analytics from demo account
          if (userData && orgId !== DEMO_ORGID) {
            FullStory.setUserVars(userData)
            if (userData.isOwner) {
              window.gtag('set', 'user_data', {
                sha256_email_address: CryptoJS.SHA256(username).toString(),
              })
              window.gtag('event', 'conversion', {
                send_to: 'AW-370002814/f1erCLnIuJIZEP6Wt7AB',
              })
              // if sessions is at least one day after user creation, send to google
              if (isNewishOrg) {
                window.gtag('event', 'conversion', {
                  send_to: 'AW-370002814/6VimCN_T8LEZEP6Wt7AB',
                })
              }
              if (isActivatedOrg) {
                window.gtag('event', 'conversion', {
                  send_to: 'AW-370002814/vB-ZCO6K67IZEP6Wt7AB',
                })
              }
            }
          }
        }
      }
      identifyFS()
    }, [orgId, username, workspaceName, workspaceId, orgName, userData]) // explicit in order to prevent rerenders

    /////////////////////////////////////////////////////
    // DIALOGS
    /////////////////////////////////////////////////////

    const settingsDialog = (state) => {
      setIsSettingsDialogOpen(state)
    }

    const cleanupDialog = (state) => {
      setIsCleanupDialogOpen(state)
    }

    const importDialog = (state) => {
      setIsImportWizardOpen(state)
    }

    /////////////////////////////////////////////////////
    // SNACKBAR
    /////////////////////////////////////////////////////

    // this is just for a funny issue where the "false" is showing on snackbar closing
    useEffect(() => {
      if (snackbar) {
        setTagSnackbar(snackbar)
      }
    }, [snackbar])

    const SlideTransition = (props) => {
      return <Slide {...props} direction="up" />
    }

    /////////////////////////////////////////////////////
    // VIEW SELECTOR
    /////////////////////////////////////////////////////

    const reset = () => {
      onReset()
      setView('home')
    }

    /////////////////////////////////////////////////////
    // MOBILE FILTERS
    /////////////////////////////////////////////////////

    const openNavbar = () => {
      setFilterOpen((filterOpen) => !filterOpen)
    }

    /////////////////////////////////////////////////////
    // BOOKMARKS
    /////////////////////////////////////////////////////

    const openBookmark = () => {
      const ele = document.getElementById('upload-button')
      setIsBookmarkOpen(ele)
    }

    const closeBookmark = () => {
      setIsBookmarkOpen(null)
    }

    const filePreview = (fileId) => {
      addToFileViewed({ fileId })
      setIsPreviewDialogOpen(true)
    }

    /////////////////////////////////////////////////////
    // COLLECTIONS
    /////////////////////////////////////////////////////

    const collectionPreview = (
      <div className="collection-preview-container">
        <Files
          openUpload={setIsUploadDialogOpen}
          previewDialog={setIsPreviewDialogOpen}
          collapseSidebar={collapseSidebar}
          onDelete={(id) => {
            setShowDeleteCollectionDialog(true)
            setDeleteCollectionId(id)
          }}
          editCollection={() => {
            setShowEditCollectionDialog(true)
          }}
          setView={setView}
          isCollection={true}
          setIsPlanPrompt={setIsPlanPrompt}
        />
      </div>
    )

    /////////////////////////////////////////////////////
    /// DRIVE
    /////////////////////////////////////////////////////

    useEffect(() => {
      if (driveFoldersNames) {
        console.log('driveFoldersNames', driveFoldersNames)
        setIsImportWizardOpen(true)
      }
    }, [driveFoldersNames])

    /////////////////////////////////////////////////////
    // UPLOAD STATUS BAR
    /////////////////////////////////////////////////////

    const uploadStatusBar = syncJob && (
      <div className="migration-status">
        <div className="migration-status-wrapper">
          <div className="migration-status-content">
            {syncJob.type === 'upload' && (
              <>
                {syncJob.totalFiles > syncJob.numberOfFilesAnalysed && (
                  <div className="h2">
                    {'Analyzing ' +
                      syncJob.numberOfFilesAnalysed +
                      '/' +
                      syncJob.totalFiles +
                      ' assets...'}
                  </div>
                )}
                {syncJob.totalFiles <= syncJob.numberOfFilesAnalysed && (
                  <div className="h2">
                    {'Successfully analyzed ' +
                      (syncJob.totalFiles === 1
                        ? syncJob.totalFiles + ' asset'
                        : syncJob.totalFiles + ' assets')}
                  </div>
                )}
              </>
            )}
            {syncJob.type === 'folders' && (
              <>
                <div className="h2">
                  {'Importing and organizing ' +
                    driveFilesCount +
                    ' assets from Google Drive...'}
                </div>
                <div className="h3 light">
                  The assets will be available once the import process is
                  completed.
                </div>
              </>
            )}
          </div>
          <div className="migration-status-buttons">
            {syncJob.totalFiles > syncJob.numberOfFilesAnalysed && (
              <div className="p">
                {'about ' +
                  Math.max(
                    (
                      (syncJob.totalFiles - syncJob.numberOfFilesAnalysed) *
                      0.04
                    ).toFixed(0),
                    2
                  ) +
                  ' minutes remaining'}
              </div>
            )}
            {syncJob.type === 'folders' && (
              <MyButton
                onClick={() => {
                  console.log('canceling!!!!!!!')
                }}
                className="migration-status-button"
                variant="text"
                color="inherit"
              >
                Cancel import
              </MyButton>
            )}
            {syncJob.type === 'upload' &&
              syncJob.totalFiles <= syncJob.numberOfFilesAnalysed && (
                <MyButton
                  onClick={closeSyncJob}
                  className="migration-status-button"
                  variant="text"
                  color="inherit"
                >
                  Okay
                </MyButton>
              )}
          </div>
        </div>
        <LinearProgress
          variant="determinate"
          value={(syncJob.numberOfFilesAnalysed / syncJob.totalFiles) * 100}
        />
      </div>
    )

    return (
      <MuiThemeProvider theme={theme}>
        {viewReady ? (
          <div className={'home-wrapper'}>
            <TopBar
              settingsDialog={settingsDialog}
              cleanupDialog={cleanupDialog}
              uploadDialog={setIsUploadDialogOpen}
              importDialog={importDialog}
              openNavbar={openNavbar}
              isBookmarkOpen={isBookmarkOpen}
              openBookmark={openBookmark}
              closeBookmark={closeBookmark}
              reset={reset}
              setSelectedList={setSelectedList}
              setShowBulkEditDialog={setShowBulkEditDialog}
              setType={setType}
              previewDialog={setIsPreviewDialogOpen}
              setIsPlanPrompt={setIsPlanPrompt}
            />
            {isPreviewDialogOpen && (
              <Preview
                isOpen={isPreviewDialogOpen}
                previewDialog={setIsPreviewDialogOpen}
                setView={setView}
                setIsPlanPrompt={setIsPlanPrompt}
              />
            )}
            {isSettingsDialogOpen && (
              <Settings
                isOpen={isSettingsDialogOpen}
                setSettingsDialogState={settingsDialog}
              />
            )}
            {isUploadDialogOpen && (
              <UploadWizard
                type={isUploadDialogOpen}
                isOpen={isUploadDialogOpen}
                setDialog={setIsUploadDialogOpen}
                currentCollection={currentCollection}
              />
            )}
            {isImportWizardOpen && (
              <ImportWizard
                isOpen={isImportWizardOpen}
                setDialog={setIsImportWizardOpen}
                walkthrough={walkthrough}
                setWalkthrough={setWalkthrough}
              />
            )}
            <div {...restRootProps} className={'drag-n-drop-wrapper'}>
              <input {...getInputProps()} />
              <div className={'content-wrapper'}>
                {!isAnonymous && (
                  <NavBar
                    filterOpen={filterOpen}
                    closeNav={openNavbar}
                    isCollapsed={collapseSidebar}
                    setView={setView}
                    view={view}
                    setSelectedList={setSelectedList}
                    reset={reset}
                    setIsSettingsDialogOpen={setIsSettingsDialogOpen}
                  />
                )}
                {!isAnonymous && (
                  <div className="collapse-sidebar">
                    <IconButton
                      className={`collapse-sidebar-button ${
                        collapseSidebar && 'open'
                      }`}
                      variant="contained"
                      size="small"
                      onClick={() => setCollapseSidebar(!collapseSidebar)}
                    >
                      <KeyboardArrowLeftIcon fontSize="inherit" />
                    </IconButton>
                  </div>
                )}
                <div className={'files-wrapper'}>
                  {view === 'home' && syncJob && uploadStatusBar}
                  {view === 'home' && (isSearchMode() || !!files?.length) && (
                    <TagsFilter />
                  )}
                  {view === 'home' && (
                    <Files
                      openUpload={setIsUploadDialogOpen}
                      previewDialog={setIsPreviewDialogOpen}
                      collapseSidebar={collapseSidebar}
                      setIsPlanPrompt={setIsPlanPrompt}
                    />
                  )}
                  {view === 'tags' && (
                    <TagsManagment
                      walkthrough={walkthrough}
                      setWalkthrough={setWalkthrough}
                    />
                  )}
                  {view === 'persons' && <Persons setView={setView} />}
                  {view === 'collections' && !currentCollection && (
                    <Collections
                      setIsSettingsDialogOpen={setIsSettingsDialogOpen}
                    />
                  )}
                  {view === 'collections' &&
                    !!currentCollection &&
                    collectionPreview}
                </div>
              </div>
            </div>
            <BulkActionBar
              open={selectedList.length > 0}
              quantity={selectedList.length}
              selectedList={selectedList}
              close={closeBulkBar}
              setShowDialog={setShowDialog}
              setShowBulkEditDialog={setShowBulkEditDialog}
              setType={setType}
            />

            {showDialog === true && (
              <ActionDialog
                actionName={'Delete'}
                action={bulkRemove}
                cancel={() => setShowDialog(false)}
                question={
                  selectedList.length === 1
                    ? `Delete ${selectedList.length} file?`
                    : `Delete ${selectedList.length} files?`
                }
                comment={
                  "You're about to permanently delete the following files, their comments and related content, and all of their data:"
                }
                color={'warning'}
                isOpen={showDialog}
                list={getList()}
              />
            )}
            <ActionDialog // PLAN UPGRADE PROMPT
              actionName={
                isAllowed(RULES.CHANGE_ORG_PLAN) ? 'View plans' : 'Okay'
              }
              action={
                isAllowed(RULES.CHANGE_ORG_PLAN)
                  ? () => {
                      setIsSettingsDialogOpen('account')
                      setIsPlanPrompt(false)
                    }
                  : () => setIsPlanPrompt(false)
              }
              cancel={() => setIsPlanPrompt(false)}
              question={'It’s time to upgrade'}
              image={LAUNCH}
              comments={[
                'You have reached the limit of your current plan.',
                isAllowed(RULES.CHANGE_ORG_PLAN) &&
                  'To add more assets, upgrade your plan.',
                !isAllowed(RULES.CHANGE_ORG_PLAN) &&
                  'To add more assets, contact your admins.',
              ]}
              //color={"warning"}
              isOpen={isPlanPrompt}
            />
            {showBulkEditDialog === true && (
              <BulkEdit
                isOpen={showBulkEditDialog}
                type={type}
                closeDialog={() => setShowBulkEditDialog(false)}
                selectedList={selectedList}
              />
            )}

            <ActionDialog
              actionName={`Tag "` + tagSuggestion + `"`}
              cancelName={`Tag "` + lastTag?.name + `"`}
              action={() => {
                renameTag({
                  id: lastTag?._id,
                  name: tagSuggestion,
                  force: !tagSuggestIsExist,
                })
                cancelTagSuggestions()
              }}
              cancel={() => {
                cancelTagSuggestions()
              }}
              question={
                tagSuggestIsExist
                  ? `Did you mean "` + tagSuggestion + `"?`
                  : `How about "` + tagSuggestion + `"?`
              }
              comments={
                tagSuggestIsExist
                  ? [
                      `We found an existing tag "` +
                        tagSuggestion +
                        `" that seems similar to "` +
                        lastTag?.name +
                        `".`,
                      `Would you like to use it instead?`,
                    ]
                  : [
                      `We found 0 files for "` +
                        lastTag?.name +
                        `", but we did find ` +
                        tagSuggestionCount +
                        ` files for "` +
                        tagSuggestion +
                        `", which we think is similiar.`,
                      `Would you like to use "` + tagSuggestion + `" instead?`,
                    ]
              }
              isOpen={!!tagIsSuggest}
            />
            <ActionDialog
              actionName={'Delete'}
              action={() => onDeleteCollection(deleteCollectionId)}
              cancel={() => setShowDeleteCollectionDialog(false)}
              question={`Delete Collection?`}
              comments={[
                'Are you sure you want to delete this collection?',
                'This action in permanent, and any colloaborators would lose access to it. Note that this will not delete the files from your workspace.',
              ]}
              color={'warning'}
              isOpen={showDeleteCollectionDialog}
            />
            <ActionDialog
              actionName={'Merge'}
              action={() => {
                mergePersons(true)
              }}
              cancel={() => {
                mergePersons(false)
              }}
              question={`Merge these people?`}
              comment={
                'It seems you have another person named ' +
                (duplicatePersons.length ? duplicatePersons[0]?.name : '') +
                '. Would you like to merge them?'
              }
              isOpen={!!duplicatePersons.length}
            />
            <CreateCollectionDialog
              isOpen={showEditCollectionDialog}
              close={() => setShowEditCollectionDialog(false)}
              //isMobile={window.mobileCheck()}
              createMode={false}
            />
            {tagSnackbar > 1 && (
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={!!snackbar}
                //TransitionComponent={TransitionUp}
                //autoHideDuration={3000}
                //onClose={closeTagSnackbar}
                //message=
              >
                <Alert severity="success" sx={{ width: '100%' }}>
                  {`We found ${tagSnackbar - 1} more ${
                    tagSnackbar === 2 ? 'file' : 'files'
                  } with this tag, and autotagged ${
                    tagSnackbar === 2 ? 'it' : 'them'
                  } for you.`}
                </Alert>
              </Snackbar>
            )}
            {
              <ActionDialog
                question="Account disabled"
                comment={
                  'Your account has been disabled. Please contact support at hello@tagbox.io to resolve this issue. Thanks.'
                }
                isOpen={accountBlock}
                actionName="Contact Support"
                action={() =>
                  (window.location.href =
                    'mailto:hello@tagbox.io?subject=Request%20to%20Reactivate%20Disabled%20Account')
                }
              />
            }
            <Snackbar
              open={isBulkDownloading}
              autoHideDuration={6000}
              TransitionComponent={SlideTransition}
            >
              <Alert
                severity="success"
                sx={{ width: '100%' }}
                icon={<CircularProgress color="inherit" size={24} />}
              >
                {`We're zippin' it! Just a few more moments...`}
              </Alert>
            </Snackbar>

            {/* add a Built with Tagbox button on mobile bottom right */}
            {window.mobileCheck() && isAnonymous && (
              <MyButton
                color="secondary"
                size="small"
                onClick={() => {
                  window.open('https://tagbox.io', '_blank')
                }}
                variant="text"
                className="try-tagbox-button"
              >
                Built with Tagbox.io
              </MyButton>
            )}
          </div>
        ) : (
          <div className="loading-spinner">
            <CircularProgress color="secondary" />
          </div>
        )}
      </MuiThemeProvider>
    )
  }
)

export default Home
