import React, { useCallback, useEffect } from 'react'
import './style.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../../store/StoresProvider.js'

import axios from 'axios'
import GoogleLogin from 'react-google-login'
import { client } from '../../../services/host'
import useDrivePicker from 'react-google-drive-picker'

const CLIENT_ID =
  '684262614313-mk1fphl6grujlq8dmplhbpfgjicfusv8.apps.googleusercontent.com'
const SCOPE =
  'https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive.activity.readonly'
const DEVELOPER_KEY = 'AIzaSyAIqsXsgeMGOt8A-tlaCHL3R0ZZiMa0z5A'

const DriveSync = observer(
  ({
    // from parent
    setDialog,
    setMenuState,
  }) => {
    const { filesStore } = useStore()

    const { getDriveFolders, setAuthResponse, access_token, nameFolders } =
      filesStore

    //const [openPicker, data, authResponse] = useDrivePicker()
    const [openPicker] = useDrivePicker()

    const handleOpenPicker = () => {
      console.log(access_token)
      setDialog(false)

      openPicker({
        clientId: CLIENT_ID,
        developerKey: DEVELOPER_KEY,
        viewId: 'FOLDERS',
        token: access_token, // pass oauth token in case you already have one
        setSelectFolderEnabled: true,
        supportDrives: true,
        multiselect: true,
        setIncludeFolders: true,
        // customViews: customViewsArray, // custom view
        callbackFunction: (data) => {
          console.log(data)
          if (data.action === 'cancel') {
            console.log('User clicked cancel/close button')
            //setDialog(true)
          }
          if (data.action === 'picked') {
            console.log('User selected files/folders', data.docs)
            //setDialog(true)
            setSelectedFolders(data)
            //setMenuState(1)
          }
          if (data.action === 'loaded') {
            console.log('Google drive loaded')
          }
        },
      })
    }

    useEffect(() => {
      if (access_token) {
        handleOpenPicker()
      }
    }, [access_token])

    /*
  useEffect(() => {
    // do anything with the selected/uploaded files
    console.log('data', data)
    if (data) {
      const foldersIds = data.docs.map((doc) => {
        return doc.id
      })
      console.log('foldersIds', foldersIds)
      //setSelectedFolders(foldersIds)
      //syncFolders(foldersIds)
    }
  }, [data])
  */

    const setSelectedFolders = async (data) => {
      console.log('data', data)
      if (data) {
        const foldersIds = data.docs.map((doc) => {
          return doc.id
        })
        console.log('foldersIds', foldersIds)
        nameFolders({ folderIds: foldersIds })
        //setSelectedFolders(foldersIds)
        //syncFolders(foldersIds)
      }
    }

    const responseGoogle = async (response) => {
      console.log('response', response)
      if (response && response.code) {
        await getRefreshToken(response)
      } else {
        console.log('Google Login is blocked')
      }
    }

    const onFail = () => {
      setTimeout(
        () =>
          alert(
            'Your browser is blocking Google from Logging in. Make sure you are not in Incognito mode. If the issue persists, contact us at hello@tagbox.io'
          ),
        1000
      )
    }

    const getRefreshToken = async (response) => {
      try {
        const { data } = await axios.post(
          'https://oauth2.googleapis.com/token',
          {
            code: response.code,
            client_id:
              '684262614313-mk1fphl6grujlq8dmplhbpfgjicfusv8.apps.googleusercontent.com',
            client_secret: 'ApVdNMKnFVXKEBWg0f1LqL2f',
            redirect_uri: client, //client, //'http://localhost:3000',
            grant_type: 'authorization_code',
          },
          {
            Host: 'oauth2.googleapis.com',
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        )
        setAuthResponse(data)
        //getDriveFolders(data)
      } catch (error) {}
    }

    return (
      <GoogleLogin
        clientId={CLIENT_ID}
        buttonText={
          window.mobileCheck() ? 'Google Drive' : 'Import from Google Drive'
        }
        onSuccess={(res) => responseGoogle(res)}
        onFailure={() => onFail()}
        prompt="consent"
        accessType="offline"
        responseType="code"
        scope={SCOPE}
        cookiePolicy={'single_host_origin'}
      />
    )
  }
)

export default DriveSync
