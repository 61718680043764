import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js' // Import useStore hook

import { MyButton } from '../_MyComponents'
import PRODUCTS from '../../assets/DemoAssetsCovers/products-cover.png'
import BRAND from '../../assets/DemoAssetsCovers/brand-cover.png'
import PEOPLE from '../../assets/DemoAssetsCovers/people-cover.png'

const DemoUpload = observer(
  ({
    // from parent
    openUpload,
  }) => {
    const { filesStore } = useStore()
    const { uploadToS3, addOrgTag, addTagToCategory } = filesStore

    const productTags = [
      ['R&R', 'Brands'],
      ['Resilience', 'Brands'],
      ['Oil', 'Product type'],
      ['Cream', 'Product type'],
      ['1000mg', 'Quantity'],
      ['2500mg', 'Quantity'],
      ['600mg', 'Quantity'],
      ['30mg', 'Quantity'],
      ['Person', 'Who'],
      ['Dog', 'Who'],
    ]

    const peopleTags = [
      ['Critics Choice Awards', 'Event'],
      ['Oscars', 'Event'],
      ['Sundance Film Festival', 'Event'],
      ['2023', 'Year'],
      ['2022', 'Year'],
      ['2021', 'Year'],
      ['Fairmont Century Plaza Hotel', 'Venue'],
      ['Dolby Theatre', 'Venue'],
      ['Park City', 'Venue'],
    ]

    const brandTags = [
      ['Phone', 'Mockup'],
      ['Computer', 'Mockup'],
      ['Leafs', 'Backgrounds'],
      ['Sky', 'Backgrounds'],
      ['Office', 'Backgrounds'],
      ['Logo', 'File type'],
      ['Brochure', 'File type'],
    ]

    function importAll(r) {
      let images = []
      r.keys().forEach((item, index) => {
        images[item.replace('./', '')] = r(item)
      })
      return Object.values(images)
    }

    const fetchImage = async (img) => {
      const res = await fetch(img)
      const imageBlob = await res.blob()
      const file = new File([imageBlob], img)
      return file
    }

    const fetchAllImages = async (imgs) => {
      const tempImgs = []
      for (const img of imgs) {
        const tempImg = await fetchImage(img)
        tempImgs.push(tempImg)
      }

      return tempImgs
    }

    const demoTags = async (tag, cat) => {
      const tempTag = await addOrgTag({ tag })
      addTagToCategory(tempTag._id, cat)
    }

    const createTags = async (tagArr) => {
      for (const tagCat of tagArr) {
        await demoTags(tagCat[0], tagCat[1])
      }
    }

    const handleClick = async (demo) => {
      let tempTags = null
      let images = null
      if (demo === 'products') {
        images = importAll(
          require.context(
            '../../assets/ProductAssets',
            false,
            /\.(webp|png|jpe?g|svg)$/
          )
        )
        tempTags = productTags
      } else if (demo === 'people') {
        images = importAll(
          require.context(
            '../../assets/PeopleAssets',
            false,
            /\.(webp|png|jpe?g|svg)$/
          )
        )
        tempTags = peopleTags
      } else if (demo === 'brand') {
        images = importAll(
          require.context(
            '../../assets/BrandAssets',
            false,
            /\.(webp|png|jpe?g|svg)$/
          )
        )
        tempTags = brandTags
      }
      openUpload()
      await createTags(tempTags)
      let imgList = await fetchAllImages(images)
      uploadToS3(imgList)
    }

    useEffect(() => {
      fetchImage()
    }, [])

    return (
      <div className="demo-files-container">
        <div className="half-circle" />
        <div className="demo-files-wrapper">
          <p className="p">Or try one of these</p>
          <div className="demo-files-buttons">
            <div
              className="flex-col df-button"
              onClick={() => handleClick('products')}
            >
              <img src={PRODUCTS} alt="products" />
              <MyButton>Product assets</MyButton>
            </div>
            <div
              className="flex-col df-button"
              onClick={() => handleClick('people')}
            >
              <img src={PEOPLE} alt="people" />
              <MyButton>People and places</MyButton>
            </div>
            <div
              className="flex-col df-button"
              onClick={() => handleClick('brand')}
            >
              <img src={BRAND} alt="brand" />
              <MyButton>Brand assets</MyButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default DemoUpload
