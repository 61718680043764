import React, { useEffect } from 'react'
import { Router, Switch, Route, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  HOME,
  THIRD_PARTY_LOGIN,
  ASSIGN_FOLDERS,
  CREATE_ORGANIZATION,
  CREATE_WORKSPACE,
  LOGIN,
  REGISTER,
  RESET_PASSWORD,
  DRIVE,
  ONBOARDING,
  PREVIEW,
  UNSUBSCRIBE,
  NEW_ORG,
} from '../../util/routes'

import history from '../../services/history'
import Home from '../Home/Home.js'
import Drive from '../Drive/Drive.js'
import Login from '../Login/Login.js'
import Register from '../Register/Register.js'
import CreateOrganization from '../CreateOrganization/CreateOrganization.js'
import ThirdPartyPermissions from '../ThirdPartyPermissions/ThirdPartyPermissions.js'
import { loadProgressBar } from 'x-axios-progress-bar'
import AssignWorkspace from '../AssignWorkspace/AssignWorkspace.js'
import CreateWorkspace from '../CreateWorkspace/CreateWorkspace.js'
import ResetPassword from '../ResetPassword/ResetPassword.js'
import Preview from '../Preview/Preview.js'
import Unsubscribe from '../Unsubscribe/Unsubscribe.js'
import StartSurvey from '../StartSurvey/StartSurvey.js'
import * as FullStory from '@fullstory/browser'

const PROD = process.env.REACT_APP_NODE_ENV === 'production'

const App = ({
  appMounted,
  appWillUnmount,
  doesUserAuthenticated,
  doesUserRegistered,
}) => {
  loadProgressBar()
  useEffect(() => {
    appMounted()
    if (PROD) {
      FullStory.init({ orgId: 'WXMWY' })
    }
    return () => {
      appWillUnmount()
    }
  }, [appMounted, appWillUnmount])

  /////////////////////////////////////////////////////
  // ANDROID BACK BUTTON
  /////////////////////////////////////////////////////

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault()
      if (history.length > 1) {
        history.goBack()
      } else {
        // Optionally, show a confirmation dialog before exiting the app
        if (window.confirm('Are you sure you want to exit the app?')) {
          navigator.app.exitApp() // This is for Cordova/PhoneGap apps. Remove if not using.
        }
      }
    }

    document.addEventListener('backbutton', handleBackButton, false)

    return () => {
      document.removeEventListener('backbutton', handleBackButton, false)
    }
  }, [history])

  // effect to update favicon and also title for whitelabel
  useEffect(() => {
    //change favicon if url contains app.communityclips.com or params saml=communityclips
    if (
      window.location.href.includes('app.communityclips.com') ||
      window.location.href.includes('saml=communityclips')
    ) {
      let link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)
      }
      link.href =
        'https://s3.eu-west-1.amazonaws.com/tagbox.public.bucket/whitelabel/communityclips_favicon.ico'

      document.title =
        'Community Clips | Chicago Suburb Media at Your Fingertips'
    }
  }, [])

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={THIRD_PARTY_LOGIN}
          component={ThirdPartyPermissions}
        />
        <Route exact path={ASSIGN_FOLDERS} component={AssignWorkspace} />
        <Route exact path={HOME} component={Home} />
        <Route exact path={DRIVE} component={Drive} />
        <Route exact path={LOGIN} component={Login} />
        <Route exact path={REGISTER} component={Register} />
        <Route
          exact
          path={CREATE_ORGANIZATION}
          component={CreateOrganization}
        />
        <Route exact path={NEW_ORG} component={StartSurvey} />
        <Route exact path={CREATE_WORKSPACE} component={CreateWorkspace} />
        <Route exact path={RESET_PASSWORD} component={ResetPassword} />
        <Route exact path={PREVIEW} component={Preview} />
        <Route exact path={UNSUBSCRIBE} component={Unsubscribe} />
      </Switch>
    </Router>
  )
}

App.propTypes = {
  appWillUnmount: PropTypes.func.isRequired,
  appMounted: PropTypes.func.isRequired,
  doesUserAuthenticated: PropTypes.bool,
  doesUserRegistered: PropTypes.bool,
}
export default App
