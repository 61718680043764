import React from 'react'
import './FiltersBar.scss'
import './mobile.scss'

import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'

//import SearchByUsers from "../SearchByUsers"
//import SearchByUpload from "../SearchByUpload"
import SearchByType from '../SearchByType/SearchByType.js'
import SearchByPeople from '../SearchByPeople/SearchByPeople.js'
import './FiltersBar.scss'

const FiltersBar = observer((props) => {
  const {
    // from parent
    filterOpen,
    closeNav,
    handleShowAll,
  } = props

  const { filesStore } = useStore()

  const { persons, files } = filesStore

  return (
    <>
      {!!files?.length ? ( //OZ is there a way to know if there are files in ws
        <div className="filters-bar-content">
          <SearchByType partialView />

          {!!persons?.length && (
            <SearchByPeople handleShowAll={handleShowAll} partialView />
          )}
        </div>
      ) : (
        <div>What can we help you find?...</div>
      )}
      {filterOpen && <div className="black-bg-mobile" onClick={closeNav}></div>}
    </>
  )
})

export default FiltersBar
