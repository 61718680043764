import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import './settings.scss'
import './mobile.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js'
import { filter } from 'lodash'
//import ChipInput from 'material-ui-chip-input'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'

import { MyButton } from '../_MyComponents'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { isEmail } from 'validator'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import ActionDialog from '../ActionDialog/ActionDialog.js'
import { metabase } from '../../services/host'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import PlanProgress from '../PlanProgress/PlanProgress.js'
import Switch from '@material-ui/core/Switch'
import { MyKeyword } from '../_MyComponents'
import ANALYTICS from '../../assets/MockSettings/mock-analytics.png'
import PERMISSIONS from '../../assets/MockSettings/mock-permissions.png'
import CUSTOMAI from '../../assets/MockSettings/mock-customAI.png'
import DRIVE from '../../assets/google-drive-icon.svg'
import DROPBOX from '../../assets/dropbox_icon.svg'
//import ONEDRIVE from '../../assets/onedrive_icon.svg'
import DriveSync from '../Drive/DriveSync/DriveSync.js'
import Button from '@material-ui/core/Button'

const Settings = observer(
  ({
    // from parent
    isOpen,
    setSettingsDialogState,
  }) => {
    const { orgsWorkspacesStore, authenticationStore } = useStore()

    const {
      ORG_ROLES,
      inviteUser: invite,
      workspaceUsers,
      updateUserIsRole,
      removeMember,
      workspaces,
      deleteWorkspace,
      createPaymentLink,
      savePaymentsInfo,
      updatePaymentsPlan,
      cancelPayments,
      organization,
      orgType,
      orgPayment,
      orgPaymentInfo,
      orgPlan,
      orgPlanLimits,
      featureFlags,
      logo,
      workspaceId, // for analytics iframe
    } = orgsWorkspacesStore

    const { username } = authenticationStore

    const [emails, setEmails] = useState([])
    const [role, setRole] = useState(Object.values(ORG_ROLES)[2])
    const [errorMessage, setErrorMessage] = useState('')
    const [dialogState, setDialogState] = useState()
    const [cancelDialog, setCancelDialog] = useState(false)
    const [switchPlanDialog, setSwitchPlanDialog] = useState(false)
    const [upgradeDialog, setUpgradeDialog] = useState(false)
    const [preventStudioDialog, setPreventStudioDialog] = useState(false)
    const [sidemenuState, setSidemenuState] = React.useState(isOpen)
    const [deleteWsValidated, setDeleteWsValidated] = React.useState(false)
    const [emailFilter, setEmailFilter] = useState('')

    const Paddle = window.Paddle
    const handleSubscribe = async ({ plan, payment }) => {
      if (plan === 'free') {
        setCancelDialog(false)
        await cancelPayments()
      } else if (
        (plan === 'studio-yearly' || plan === 'studio-monthly') &&
        workspaceUsers.length > 3
      ) {
        setPreventStudioDialog('open')
      } else {
        if (orgPlan === 'free') {
          //creating a plan
          handleClose()
          const { url } = await createPaymentLink(plan)
          Paddle.Checkout.open({
            //produt: 22627,
            override: url,
            successCallback: (data, err) => {
              if (err) {
                //do something?
                console.log('successCallback error', err)
              } else {
                setSettingsDialogState('account')
                savePaymentsInfo({ data, payment })
                window.gtag('event', 'conversion', {
                  send_to: 'AW-370002814/-7kwCLzIuJIZEP6Wt7AB',
                })
              }
            },
          })
        } else {
          //changing to a diffrent plan
          await updatePaymentsPlan({ plan, payment })
        }
      }
    }

    const handleContactUs = () => {
      window.open('mailto:hello@tagbox.io')
    }

    const handleClose = () => {
      setSettingsDialogState(null)
    }

    const addEmail = (email) => {
      if (
        //studio and free plans have a limit of 3 users (save in orgPlanLimits.userCountLimit)
        (orgPlan === 'studio' || orgPlan === 'free') &&
        orgPlanLimits.userCountLimit > 0 &&
        workspaceUsers.length + emails.length >= orgPlanLimits.userCountLimit
      ) {
        setUpgradeDialog(true)
      } else {
        if (!isEmail(email)) {
          setErrorMessage(`Invalid email '${email}'`)
        } else {
          setErrorMessage('')
          setEmails([...emails, email])
        }
      }
    }

    const addEmails = (newEmails) => {
      //studio and free plans have a limit of 3 users (save in orgPlanLimits.userCountLimit)
      if (
        (orgPlan === 'studio' || orgPlan === 'free') &&
        orgPlanLimits.userCountLimit > 0 &&
        workspaceUsers.length + emails.length + newEmails.length >
          orgPlanLimits.userCountLimit
      ) {
        setUpgradeDialog(true)
      } else {
        var validEmails = []
        for (let email of newEmails) {
          if (!isEmail(email)) {
            setErrorMessage(`Invalid email '${email}'`)
          } else {
            setErrorMessage('')
            validEmails.push(email.toLowerCase())
          }
        }
        setEmails([...emails, ...validEmails])
      }
    }

    const deleteEmail = (email) => {
      setErrorMessage('')
      if (email) {
        setEmails(filter(emails, (e) => e !== email))
      }
    }

    const deleteUser = (obj) => {
      setDialogState(obj)
    }

    const handleChangeRole = (ev) => {
      setRole(ev.target.value)
    }

    const handleValidation = (e) => {
      if (e.target.value === 'delete workspace') {
        setDeleteWsValidated(true)
      } else {
        setDeleteWsValidated(false)
      }
    }

    const handleDeleteWorkspace = () => {
      setSettingsDialogState(null)
      deleteWorkspace()
    }

    //handle paste on chip input
    const handlePaste = (event) => {
      const clipboardData = event.clipboardData
      const pastedText = clipboardData.getData('text')
      const separators = [',', ' ', ';', '\n', '\t', '\r', '\r\n']

      // Split pasted text using separators
      const chips = pastedText.split(new RegExp(`[${separators.join('')}]`))
      if (chips.length > 1) {
        event.preventDefault() // prevent the default paste behavior - so input value is not pasted
        addEmails(chips)
      } else {
        //do nothing
        return
      }
    }

    const accountNameSec = (
      <div className={'settings-section'}>
        <div className={'h2'}>Account details</div>
        <div className="account-details-wrapper">
          <div className="account-details">
            <div className="account-avatar">
              <AccountBoxIcon fontSize="large" />
            </div>
            <div className="account-texts">
              <div className="h2">name</div>
              <div className="h3">date</div>
            </div>
          </div>
          <div className="account-button">
            <MyButton
              onClick={() => setCancelDialog(true)}
              variant="contained"
              color="secondary"
              size="small"
              disabled={orgPlan === 'free'}
            >
              <label>Edit organizations name</label>
            </MyButton>
          </div>
          <div></div>
        </div>
      </div>
    )

    const subscriptionSec = orgType === 'regular' && (
      <div className={'settings-section'}>
        <h2 className={'h2'}>Subscription</h2>
        <div className="settings-card-container">
          {/* SOLO PLAN */}
          <div className={`settings-card ${orgPlan === 'free' && 'selected'}`}>
            <div className="empty-state-content">
              <div className="h2">Solo Plan</div>
              <div className="es-card-desc">Free</div>
            </div>
            <MyButton
              onClick={() => setCancelDialog(true)}
              className="pricing-button"
              variant="contained"
              color="secondary"
              size="small"
              disabled={orgPayment === 'free'}
            >
              <label>Change plan</label>
            </MyButton>
          </div>

          {/* STUDIO PLAN */}
          <div
            className={`settings-card ${orgPlan === 'studio' && 'selected'}`}
          >
            <div className="h2">Studio Plan</div>
            <div className="es-card-desc">
              $150/month
              <br />
              ($1800 total)
            </div>
            <MyButton
              onClick={() =>
                switchPlan({
                  plan: 'studio-yearly',
                  payment: 'studio-yearly',
                })
              }
              className="pricing-button"
              variant="contained"
              color="secondary"
              size="small"
              disabled={orgPayment === 'studio-yearly'}
            >
              <label>Select Yearly</label>
            </MyButton>
            <div className="es-card-desc">
              or
              <br />
              $180/month
            </div>
            <MyButton
              onClick={() =>
                switchPlan({
                  plan: 'studio-monthly',
                  payment: 'studio-monthly',
                })
              }
              className="pricing-button"
              variant="contained"
              color="secondary"
              size="small"
              disabled={orgPayment === 'studio-monthly'}
            >
              <label>Select Monthly</label>
            </MyButton>
          </div>

          {/* COMPANY PLAN */}
          <div
            className={`settings-card ${orgPlan === 'company' && 'selected'}`}
          >
            <div className="h2">Company Plan</div>
            <div className="es-card-desc">
              $250/month
              <br />
              ($3000 total)
            </div>
            <MyButton
              onClick={() =>
                switchPlan({
                  plan: 'company-yearly',
                  payment: 'company-yearly',
                })
              }
              className="pricing-button"
              variant="contained"
              color="secondary"
              size="small"
              disabled={orgPayment === 'company-yearly'}
            >
              <label>Select Yearly</label>
            </MyButton>
            <div className="es-card-desc">
              or
              <br />
              $300/month
            </div>
            <MyButton
              onClick={() =>
                switchPlan({
                  plan: 'company-monthly',
                  payment: 'company-monthly',
                })
              }
              className="pricing-button"
              variant="contained"
              color="secondary"
              size="small"
              disabled={orgPayment === 'company-monthly'}
            >
              <label>Select Monthly</label>
            </MyButton>
          </div>

          {/* ENTERPRISE PLAN */}
          <div
            className={`settings-card ${
              orgPlan === 'enterprise' && 'selected'
            }`}
          >
            <div className="empty-state-content">
              <div className="h2">Enterprise Plan</div>
              {!orgPaymentInfo && (
                <div className="es-card-desc">Custom pricing</div>
              )}
              {!!orgPaymentInfo?.yearly && (
                <div className="es-card-desc">
                  {`$${
                    Math.round((orgPaymentInfo.yearly.price / 12) * 10) / 10
                  }/month`}
                  <br />
                  (${orgPaymentInfo.yearly.price} total)
                </div>
              )}
            </div>
            {!orgPaymentInfo && (
              <MyButton
                onClick={handleContactUs}
                className="pricing-button"
                variant="contained"
                color="secondary"
                size="small"
              >
                <label>Contact us</label>
              </MyButton>
            )}
            {!!orgPaymentInfo?.yearly && (
              <MyButton
                onClick={() =>
                  switchPlan({
                    plan: orgPaymentInfo.yearly.id,
                    payment: 'enterprise-yearly',
                  })
                }
                className="pricing-button"
                variant="contained"
                color="secondary"
                size="small"
                disabled={orgPayment === 'enterprise-yearly'}
              >
                <label>Select Yearly</label>
              </MyButton>
            )}
            {!!orgPaymentInfo?.monthly && !!orgPaymentInfo.yearly && (
              <div className="es-card-desc">or</div>
            )}
            {!!orgPaymentInfo?.monthly && (
              <div className="es-card-desc">{`$${orgPaymentInfo.monthly.price}/month`}</div>
            )}
            {!!orgPaymentInfo?.monthly && (
              <MyButton
                onClick={() =>
                  switchPlan({
                    plan: orgPaymentInfo.monthly.id,
                    payment: 'enterprise-monthly',
                  })
                }
                className="pricing-button"
                variant="contained"
                color="secondary"
                size="small"
                disabled={orgPayment === 'enterprise-monthly'}
              >
                <label>Select Monthly</label>
              </MyButton>
            )}
            {!orgPaymentInfo && (
              <div className="es-card-desc">
                A custom plan tailored for your requirements. <br />
                <a
                  target="_blank"
                  href="https://tagbox.io/pricing"
                  rel="noreferrer"
                >
                  Read more.
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    )

    const planProgressSec = orgType === 'regular' && (
      <div className={'settings-section'}>
        <h2 className={'h2'}>Plan status</h2>
        <PlanProgress hasAction={false} />
      </div>
    )

    const deleteWorkspaceSec = (
      <div className={'settings-section'}>
        <h2 className={'h2'}>Delete workspace</h2>
        {/* if this is the only WS - prevent delete */}
        {workspaces.length === 1 && (
          <div>
            <div>
              This is your only workspace.
              <br />
              Please create a new workspace before deleting this one.
            </div>
          </div>
        )}
        {/* if you have muliple WS - allow delete */}

        <div>
          Note: deleted workspaces will no longer be available,
          <br />
          including all content and tags added so far.
          <br />
          <br />
          To delete this workspace, please type "delete workspace" below.
        </div>
        <TextField
          variant="outlined"
          onChange={(e) => handleValidation(e)}
          size="small"
          placeholder='type "delete workspace"'
          className={'delete-workspace-validation'}
        />
        <MyButton
          color={'primary'}
          disabled={!deleteWsValidated}
          variant="contained"
          onClick={handleDeleteWorkspace}
        >
          Delete this workspace
        </MyButton>
      </div>
    )

    const analyticsSec = (
      <>
        {!featureFlags?.analytics?.enabled && (
          <div className="settings-content mock">
            <div className="mock-title">
              <h1 className="h1">Analytics</h1>
              <MyKeyword text="Enterprise feature" disabled />
            </div>

            <p className="p light">
              Get insight into user activity and asset usage.
            </p>
            <div className="automation-container">
              <div className="flex-row automation-toggle-section">
                <div className="flex-row">
                  <Switch
                    //className={"share-toggle"}
                    checked={false}
                    color="primary"
                    disabled
                  />
                  <div className="flex-col FMP">
                    <h2 className="h2">
                      Analytics isn't part of your current plan. Contact us at
                      hello@tagbox.io to inquire about an upgrade.
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <img className="mock-image" src={ANALYTICS} alt="Analytics" />
          </div>
        )}
        {featureFlags?.analytics?.enabled && (
          <div className="analytics-wrapper flex-row">
            <iframe
              title="Workspace analytics"
              //src={metabase + "?date=past30days~&workspace=" + workspaceId + "#hide_parameters=workspace"}
              src={
                metabase +
                '?workspace=' +
                workspaceId +
                '#hide_parameters=workspace'
              }
              frameborder="0"
              width="100%"
              height="100%"
              allowtransparency
            />
          </div>
        )}
      </>
    )

    const permissionsSec = (
      <div className="settings-content mock">
        <div className="mock-title">
          <h1 className="h1">Roles & permissions</h1>
          <MyKeyword text="Enterprise feature" disabled />
        </div>
        <p className="p light">
          Create new roles and have full control over who can do what.{' '}
          <a
            href="https://tagbox.notion.site/Roles-permissions-47ebe2fccf8b4eb4a122c335433e4484"
            target="_blank"
            rel="noreferrer"
            className="more-link"
          >
            Learn more
          </a>
        </p>
        <div className="automation-container">
          <div className="flex-row automation-toggle-section">
            <div className="flex-row">
              <Switch
                //className={"share-toggle"}
                checked={false}
                color="primary"
                disabled
              />
              <div className="flex-col FMP">
                <h2 className="h2">
                  Custom permissions isn't part of your current plan. Contact us
                  at hello@tagbox.io to inquire about an upgrade.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <img className="mock-image" src={PERMISSIONS} alt="Permissions" />
      </div>
    )

    const customAISec = (
      <div className="settings-content mock">
        <div className="mock-title">
          <h1 className="h1">Custom AI training</h1>
          <MyKeyword text="Enterprise feature" disabled />
        </div>
        <p className="p light">
          Save tagging time by training our AI to identify specific objects and
          scenes.
        </p>
        <div className="automation-container">
          <div className="flex-row automation-toggle-section">
            <div className="flex-row">
              <Switch
                //className={"share-toggle"}
                checked={false}
                color="primary"
                disabled
              />
              <div className="flex-col FMP">
                <h2 className="h2">
                  Custom Ai training isn't part of your current plan. Contact us
                  at hello@tagbox.io to inquire about an upgrade.
                </h2>
              </div>
            </div>
          </div>
        </div>
        <img className="mock-image" src={CUSTOMAI} alt="Custom AI" />
      </div>
    )

    const membersSec = (
      <div className="settings-section">
        <h2 className="h2">Members</h2>
        <div className="members">
          <div className="add-user-box">
            {/* <ChipInput
              placeholder="Add an email and click 'Enter'"
              value={emails}
              classes={{ root: 'chip-input' }}
              color="primary"
              variant="standard"
              disableUnderline={true}
              onAdd={(email) => addEmail(email.toLowerCase())}
              newChipKeys={['Enter', 'Comma', ',', 'Space', ' ']}
              onPaste={handlePaste}
              onDelete={(email, index) =>
                deleteEmail(email?.toLowerCase(), index)
              }
            /> */}
            <Autocomplete
              multiple
              className="custom-autocomplete"
              freeSolo
              value={emails}
              onChange={(event, newValue, reason) => {
                if (reason === 'createOption') {
                  // Handle adding new email
                  addEmail(newValue[newValue.length - 1])
                } else if (reason === 'removeOption') {
                  // Handle deleting an email
                  const removedEmail = emails.find(
                    (email) => !newValue.includes(email)
                  )
                  if (removedEmail) {
                    deleteEmail(removedEmail)
                  }
                }
              }}
              options={[]} // No predefined options as this is freeform input
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    variant="outlined"
                    label={option}
                    color="primary"
                    {...getTagProps({ index })}
                    className="custom-chip"
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  className="custom-input"
                  onPaste={handlePaste}
                  {...params}
                  variant="standard"
                  placeholder="Add an email and click 'Enter'"
                  //error={Boolean(errorMessage)}
                  //helperText={errorMessage}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
            <FormControl variant="outlined" className="select-input">
              <Select value={role} onChange={handleChangeRole}>
                {Object.values(ORG_ROLES)
                  .filter(
                    (role) => !role.toLowerCase().startsWith('collection')
                  ) //remove collection roles
                  .map((role) => (
                    <MenuItem key={role} value={role}>
                      <span style={{ textTransform: 'capitalize' }}>
                        {role}
                      </span>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <MyButton
              variant="contained"
              color="primary"
              className="invite-button"
              disabled={!emails.length}
              onClick={() => {
                invite({ emails, role }).then(() => {
                  setEmails([])
                })
              }}
            >
              Invite
            </MyButton>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
          </div>
          <div
            className="read-more"
            onClick={() => setSidemenuState('permissions')}
          >
            Read more about Roles & Permissions
          </div>
          <div className="members-list-wrapper">
            <div className="members-list-title">Members</div>
            <div className="email-filter">
              <TextField
                variant="outlined"
                onChange={(e) => setEmailFilter(e.target.value.toLowerCase())}
                size="small"
                placeholder="Filter by email"
                className={'delete-workspace-validation'}
                value={emailFilter}
              />
            </div>
            <div className="members-list">
              {workspaceUsers
                .filter(
                  (user) =>
                    user.userId.email.includes(emailFilter) &&
                    user.role !== 'collectionUser'
                )
                .map(({ userId, workspaceId, _id, role }, index) => (
                  <div key={index} className="members-list-names">
                    <div className={'p name'}>{userId.username}</div>
                    <FormControl
                      className="members-select-input"
                      variant="outlined"
                    >
                      <Tooltip
                        enterDelay={500}
                        title={
                          userId.username === username
                            ? 'You can’t change your own permissions'
                            : ''
                        }
                      >
                        <Select
                          disabled={userId.username === username}
                          value={role}
                          onChange={(event) => {
                            const user = workspaceUsers.find(
                              (user) => userId.username === user.userId.username
                            )
                            if (user) {
                              updateUserIsRole({
                                userId: user.userId._id,
                                role: event.target.value,
                              })
                            }
                          }}
                        >
                          {Object.values(ORG_ROLES)
                            .filter(
                              (role) =>
                                !role.toLowerCase().startsWith('collection')
                            ) //remove collection roles
                            .map((role) => (
                              <MenuItem
                                key={`${username}-${role}`}
                                value={role}
                              >
                                <span style={{ textTransform: 'capitalize' }}>
                                  {role}
                                </span>
                              </MenuItem>
                            ))}{' '}
                        </Select>
                      </Tooltip>
                    </FormControl>

                    {
                      <IconButton
                        className={`member-delete ${
                          userId.username === username && 'invisible'
                        }`}
                        size="small"
                        aria-label="upload picture"
                        component="span"
                        onClick={() =>
                          deleteUser({ username: userId.username, userId })
                        }
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                  </div>
                ))}
              {workspaceUsers
                .filter(
                  (user) =>
                    user.userId.email.includes(emailFilter) &&
                    user.role === 'collectionUser'
                )
                .map(({ userId, workspaceId, _id, role }, index) => (
                  <div key={index} className="members-list-names">
                    <div className={'p name'}>{userId.username}</div>
                    <FormControl
                      className="members-select-input"
                      variant="outlined"
                    >
                      {
                        <Tooltip
                          enterDelay={500}
                          title={
                            "You can't change permissions for a collection user. Add them as workspace users to give them full permissions."
                          }
                        >
                          <Select disabled value={'collectionUser'}>
                            {
                              <MenuItem
                                key={`${username}-${role}`}
                                value={'collectionUser'}
                              >
                                <span style={{ textTransform: 'capitalize' }}>
                                  Collection user
                                </span>
                              </MenuItem>
                            }
                          </Select>
                        </Tooltip>
                      }
                    </FormControl>

                    {userId.username !== username && (
                      <IconButton
                        className={'member-delete'}
                        size="small"
                        aria-label="upload picture"
                        component="span"
                        onClick={() =>
                          deleteUser({ username: userId.username, userId })
                        }
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    )

    const importSec = (
      <div className="settings-form">
        <div className="settings-title">Where do you store your files?</div>
        <div className="cloud-service-link">
          <img src={DRIVE} alt="google drive" />
          <DriveSync />
        </div>
        <div className="cloud-service-link">
          <img src={DROPBOX} alt="dropbox" />
          <Button variant="outlined" disabled>
            {window.mobileCheck() ? 'Dropbox' : 'Sign up with Dropbox'}
          </Button>
          <div className="coming-soon">Coming soon!</div>
        </div>
        {/*<div className="cloud-service-link">
        <img src={ONEDRIVE} alt="dropbox" />
        <Button variant="outlined" disabled>
          {window.mobileCheck() ? 'OneDrive' : 'Sign up with OneDrive'}
        </Button>
        <div className="coming-soon">Coming soon!</div>
      </div>*/}
      </div>
    )

    const switchPlan = (newPlan) => {
      if (orgPlan === 'free') {
        handleSubscribe(newPlan)
      } else {
        setSwitchPlanDialog(newPlan)
      }
    }

    const confirmSwitchPlan = (newPlan) => {
      handleSubscribe(newPlan)
      setSwitchPlanDialog(false)
    }

    return (
      <Dialog
        onClose={handleClose}
        fullScreen
        maxWidth={'lg'}
        aria-labelledby="simple-dialog-title"
        open={!!isOpen}
        className="settings-dialog"
      >
        <div className="settings-wrapper">
          <div className="settings-top-bar">
            {!!organization && logo && (
              <img
                className="logo"
                src={logo}
                alt="Logo"
                onClick={handleClose}
                style={{ cursor: 'pointer' }}
              />
            )}
            <IconButton
              className="close-dialog-setting"
              onClick={handleClose}
              component="span"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="settings-main">
            <div className="settings-sidebar">
              <h1 className="h1">Settings</h1>
              <List
                className="settings-nav"
                component="nav"
                aria-labelledby="nested-list-subheader"
                sx={{
                  height: 264,
                  flexGrow: 1,
                  maxWidth: 400,
                  overflowY: 'auto',
                }}
              >
                <ListItemButton
                  selected={sidemenuState === 'workspace'}
                  className="settings-menu-button"
                >
                  <ListItemText
                    primary="Workspace"
                    onClick={() => setSidemenuState('workspace')}
                  />
                </ListItemButton>

                <ListItemButton
                  selected={sidemenuState === 'account'}
                  className="settings-menu-button"
                >
                  <ListItemText
                    primary="Account"
                    onClick={() => setSidemenuState('account')}
                  />
                </ListItemButton>

                <ListItemButton
                  selected={sidemenuState === 'analytics'}
                  className="settings-menu-button"
                >
                  <ListItemText
                    primary="Analytics"
                    onClick={() => setSidemenuState('analytics')}
                  />
                </ListItemButton>
                <ListItemButton
                  selected={sidemenuState === 'permissions'}
                  className="settings-menu-button"
                >
                  <ListItemText
                    primary="Roles & permissions"
                    onClick={() => setSidemenuState('permissions')}
                  />
                </ListItemButton>
                <ListItemButton
                  selected={sidemenuState === 'customAI'}
                  className="settings-menu-button"
                >
                  <ListItemText
                    primary="Custom AI training"
                    onClick={() => setSidemenuState('customAI')}
                  />
                </ListItemButton>
                {false && (
                  <ListItemButton
                    selected={sidemenuState === 'import'}
                    className="settings-menu-button"
                  >
                    <ListItemText
                      primary="Import"
                      onClick={() => setSidemenuState('import')}
                    />
                  </ListItemButton>
                )}
              </List>
            </div>

            <div className="settings-content-wrapper">
              {/* ACCOUNT */}

              {sidemenuState === 'account' && (
                <div className="settings-content">
                  <h1 className="h1">Account settings</h1>

                  {/* {accountNameSec} */}

                  {subscriptionSec}

                  {planProgressSec}

                  <div
                    className="read-more"
                    onClick={() =>
                      window.open('https://www.tagbox.io/pricing', '_blank')
                    }
                  >
                    Read more about our pricing
                  </div>
                </div>
              )}

              {/* WORKSPACE */}

              {sidemenuState === 'workspace' && (
                <div className="settings-content">
                  <h1 className="h1">Workspace settings</h1>

                  {membersSec}

                  {workspaces.length > 1 && deleteWorkspaceSec}
                </div>
              )}

              {/* ANALYTICS */}

              {sidemenuState === 'analytics' && <>{analyticsSec}</>}

              {sidemenuState === 'permissions' && <>{permissionsSec}</>}

              {sidemenuState === 'customAI' && <>{customAISec}</>}

              {/* IMPORTS */}

              {sidemenuState === 'import' && (
                <div className="settings-analytics">{importSec}</div>
              )}
            </div>
          </div>
        </div>
        <ActionDialog
          actionName={'remove subscription'}
          action={() => handleSubscribe({ plan: 'free', payment: 'free' })}
          cancel={() => setCancelDialog(false)}
          question={`Cancel your subscription?`}
          comment={
            "You're about to permanently cancel your subscription. This might lead to a loss of data."
          }
          color={'warning'}
          isOpen={cancelDialog}
        />
        <ActionDialog
          actionName={'Change plan'}
          action={() => confirmSwitchPlan(switchPlanDialog)}
          cancel={() => setSwitchPlanDialog(false)}
          question={`Change your subscription?`}
          comment={
            'Switching your subscription plan will take effect immediately and may result in a new charge.'
          }
          isOpen={switchPlanDialog}
        />
        <ActionDialog
          actionName={'Upgrade'}
          action={() => {
            setUpgradeDialog(false)
            setSidemenuState('account')
          }}
          cancel={() => setUpgradeDialog(false)}
          question={`Upgrade plan?`}
          comment={
            "You've used all of the users in current plan. Would you like to upgrade to the Company plan?"
          }
          //color={"warning"}
          isOpen={upgradeDialog}
        />
        <ActionDialog
          actionName={'Okay'}
          action={() => {
            setPreventStudioDialog(false)
          }}
          //cancel={() => setUpgradeDialog(false)}
          question={`Users acceed plan limit`}
          comment={
            'The Studio plan is limited to 3 users. Since you already have more than that, please select a more advanced plan.'
          }
          //color={"warning"}
          isOpen={preventStudioDialog}
        />
        {dialogState?.username && (
          <div className="remove-dialog-container">
            <div className="remove-dialog">
              <div className="remove-dialog-title">Remove member?</div>
              <div className="username-remove-title">
                {dialogState.username}
              </div>
              <MyButton
                variant="contained"
                color="primary"
                className="remove-member-button"
                onClick={() => {
                  removeMember(dialogState)
                  setDialogState('')
                }}
              >
                Remove
              </MyButton>
              <MyButton onClick={() => setDialogState('')}>Cancel</MyButton>
            </div>
          </div>
        )}
      </Dialog>
    )
  }
)

export default Settings
