import React, { useState, useEffect, useStyle } from 'react'
import './CollectionShareDialog.scss'
import './mobile.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../store/StoresProvider.js' // Import useStore hook

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import Snackbar from '@material-ui/core/Snackbar'
import CollectionPermissionsDialog from '../CollectionPermissionsDialog/CollectionPermissionsDialog.js'
import Divider from '@material-ui/core/Divider'
import CAMERA from '../../assets/find-my-photo-icon.svg'

const CollectionShareDialog = observer(
  ({
    // from parent
    open,
    setOpen,
  }) => {
    const { filesStore } = useStore()

    const {
      currentCollection,
      updateCollectionPrivacy,
      getCurrentCollection,
      updateCollectionFMP,
    } = filesStore

    const [urlSnackbarOpen, setUrlSnackbarOpen] = useState(false)
    const [shareToggle, setShareToggle] = useState(null)
    const [findMyPhotoToggle, setFindMyPhotoToggle] = useState(false)

    const handleClose = () => {
      setOpen(false)
      getCurrentCollection(currentCollection._id)
    }

    const handleToggleShare = (e) => {
      setShareToggle(!shareToggle)
      updateCollectionPrivacy({
        id: currentCollection._id,
        publicMode: !shareToggle,
      })
    }

    const handleToggleFMP = (e) => {
      setFindMyPhotoToggle(!findMyPhotoToggle)
      updateCollectionFMP({
        id: currentCollection._id,
        isFMP: !findMyPhotoToggle,
      })
    }

    const copyUrl = (url) => {
      setUrlSnackbarOpen(true)
      navigator.clipboard.writeText(url)
    }

    useEffect(() => {
      setShareToggle(currentCollection.publicMode)
      setFindMyPhotoToggle(currentCollection.isFMP)
    }, [currentCollection])

    const closeUrlSnackbar = (e, reason) => {
      if (reason === 'clickaway') {
        return
      }
      setUrlSnackbarOpen(false)
    }

    /////////////////////
    ///  FIND MY PHOTOS
    /////////////////////

    const copyLink = (type = 'reg') => {
      const url =
        type === 'reg'
          ? window.location.href
          : window.location.href + '&openfmp=true'
      return (
        <div className={'copy-url ' + (!shareToggle ? 'disabled' : '')}>
          <Button
            className={'url'}
            disableRipple
            onClick={() => copyUrl(url)}
            disabled={!shareToggle}
          >
            {url}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            //style={{backgroundColor: "white", outline: "1px solid #E9E9E7"}}
            onClick={() => copyUrl(url)}
            disabled={!shareToggle}
          >
            Copy
          </Button>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={urlSnackbarOpen}
            autoHideDuration={4000}
            onClose={closeUrlSnackbar}
            message="Link copied!"
          />
        </div>
      )
    }

    const findMyPhotoSetup = (
      <div className="automation-container">
        <div className="flex-row automation-toggle-section">
          <div className="flex-row">
            <Switch
              //className={"share-toggle"}
              checked={findMyPhotoToggle}
              color="primary"
              onChange={handleToggleFMP}
            />
            <div className="flex-col FMP">
              <h2 className="h2">Find My Photos</h2>
              <p className="p">
                Allow people to find themselves in the photos with face
                recognition.{' '}
                <a
                  target="_blank"
                  href="https://tagbox.notion.site/Find-My-Photo-links-e87aadd2ed6d4c1dae11467d157a9b04"
                  rel="noreferrer"
                >
                  Learn more
                </a>
                .
              </p>
            </div>
          </div>
          <img src={CAMERA} alt="share icon" />
        </div>
        {!!findMyPhotoToggle && copyLink('fmp')}
      </div>
    )

    /////////////////////
    ///  main component
    /////////////////////

    return (
      <Dialog
        onClose={handleClose}
        maxWidth={'md'}
        fullWidth
        aria-labelledby="simple-dialog-title"
        open={open}
        className="collection-permissions"
      >
        <DialogTitle id="form-dialog-title">Share collection</DialogTitle>
        <DialogContent className="share-collection-section share-to-web">
          <div className="share-to-web-action">
            <div className="flex-row">
              <Switch
                className={'share-toggle'}
                checked={shareToggle}
                color="primary"
                onChange={handleToggleShare}
              />
              <div className={'h2'}>Share to web</div>
            </div>
            <div className={'title-4'}>
              Anyone with the link will be able to view this collection
            </div>
          </div>

          {copyLink()}
          {!!shareToggle && findMyPhotoSetup}
        </DialogContent>
        <DialogContent>
          <Divider flexItem style={{ width: '100%', height: '1px' }} />
        </DialogContent>
        <CollectionPermissionsDialog />
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)

export default CollectionShareDialog
